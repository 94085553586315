import React, { useRef, useState } from 'react';
import axiosInstance from '../../config/axiosInstance';

function CreateModuleLessonFile({isAddingTopicLessonFile,CancelTopicLessonFileAddition, lesson, setModules}) {
    const [formData, setFormData] = useState({"file_type":"","url":""})
    const fileInputRef = useRef(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [ showAlertMessage, setShowAlertMessage] = useState(false)

    const handleSubmit = async (e)=>{
        e.preventDefault()
        const file = fileInputRef.current.files[0];
        const form_data = new FormData();
        if (file) {
            form_data.append("file", file);
        } 
        

        form_data.append("file_type", formData.file_type);
        form_data.append("url", formData.url);
        form_data.append('topic_lesson',lesson.id);

        try {
            setAlertMessage("Submitting form , please wait....")
            setShowAlertMessage(true)

            const response = await axiosInstance.post(`/topic-lesson-file/`, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            const responseData = response.data
            const status = response.status
            const { message, data } = responseData
            setAlertMessage(message)
            if(status==201){
                setModules(prev=>prev.map(item=>{
                    if(item.id==lesson.id){
                        const files = lesson.files 
                        const file = {...data , file:getPathFromUrl(data.file) }
                        const newLesson = {...item,files:[...files,file]}
                        return  newLesson
                    }
                    return item
                }))
                setFormData({"file_type":"","url":""})
                fileInputRef.current.value = "";
            }

        } catch (error) {
            setAlertMessage("Error ocurred , try again later")
        }finally{
            setTimeout(()=>{
                setShowAlertMessage(false)
                setAlertMessage("")
            },2000)
        }

    }

    function getPathFromUrl(url) {
        try {
            const parsedUrl = new URL(url); // Parse the URL
            return parsedUrl.pathname; // Extract and return the path
        } catch (error) {
            console.error("Invalid URL:", error);
            return null;
        }
    }

    return <form onSubmit={handleSubmit} encType="multipart/form-data" className={`form ${!isAddingTopicLessonFile ? "d-none" : ""}`}>
        <h5>Save topic lesson file</h5>
        <small><b>Lesson Name</b> {lesson.name}</small> <br/>
        <small><b>Lesson Description</b> {lesson.description}</small>
        <div className="form-group mt-2">
            <label className="label" htmlFor="file_type">File Type</label>
            <select required value={formData.file_type} onChange={(e)=>setFormData(prev=>({...prev,"file_type":e.target.value}))} name="file_type" id="file_type" className="form-control">
                <option value="">--select--</option>
                <option value="IMAGE">IMAGE</option>
                <option value="DOCUMENT">DOCUMENT</option>
                <option value="URL">VIDEO URL</option>
            </select>
        </div>
        <div className="form-group mt-2">
            <label className="label" htmlFor="file_type">File Type</label>
            <input ref={fileInputRef} required={formData.file_type!=="URL" ? true : false} name="file" className="form-control" type="file" />
        </div>

        <div className="form-group mt-2">
            <label className="label" htmlFor="url">url</label>
            <textarea required={formData.file_type==="URL" ? true : false} value={formData.url} name="url"  onChange={(e)=>setFormData(prev=>({...prev,"url":e.target.value}))} className="form-control" />
        </div>

        <div className={`alert alert-info mt-3  ${!showAlertMessage ? "d-none":""}`}>
            {alertMessage}
        </div>


        <div className={`form-group mt-3 ${showAlertMessage ? "d-none":""}`}>
            <button className={`btn btn-sm btn-outline-success`} type="submit">
                Save file
            </button> &nbsp;&nbsp;&nbsp;
            <button className={`btn btn-sm btn-outline-secondary`} onClick={()=>CancelTopicLessonFileAddition()} type="button">
                Cancel
            </button>
        </div>
    </form>
}

export default CreateModuleLessonFile