import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import { Link, useParams } from 'react-router-dom';
import FormatDate from '../partials/FormatDate';
import PaginationControls from '../partials/PaginationControls';
function ResumeOrders() {

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })



    const [orders, setOrders] = useState([])
    const [service, setService] = useState({ "title": "", "description": "" })

    useEffect(() => {
        fetchOrders()

    }, [])



    const fetchOrders = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data } = await axiosInstance.get(`/admin/orders/resume-writing`)
            const { orders, service } = data
            console.log({ orders, service })
            setOrders(orders)
            setService(service)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }


    // Pagination state

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    // Calculate the indices for the data to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(orders.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className='col-md-10 mt-1'>
                    <div className='card mt-2 ml-2 '>
                        <div className='card-body'>
                            <div className='col-md-12'>
                                <h5 className="card-title ">Orders </h5>
                                <CustomAlertMessage alertType={alertMessage.type}
                                    showAlertMessage={alertMessage.display}
                                    alertMessage={alertMessage.message}
                                />



                                <div className='row mt-4'>
                                    <div className='col-md-12 col-sm-12'>
                                        <h6>{service?.title}</h6>
                                        <p>
                                            {service?.description}
                                        </p>
                                    </div>

                                    <div className='col-md-12 col-sm-12'>
                                        <table className='table mt-2 table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Order Number</th>
                                                    <th>Status</th>
                                                    <th>Customer</th>
                                                    <th>Created by</th>
                                                    <th>Created At</th>
                                                    <th>Updated At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map(order => <tr key={order?.id}>
                                                    <td>{order?.id}</td>
                                                    <td>{order?.order_number}</td>
                                                    <td>{order?.status}</td>
                                                    <td>{order?.user?.email}</td>
                                                    <td>{order?.created_by?.email}</td>
                                                    <td><FormatDate dateString={order.created_at} /></td>
                                                    <td><FormatDate dateString={order.updated_at} /></td>
                                                    <td>
                                                        <Link to={`/admin/order/view/${order.id}`} className='btn btn-xs btn-outline-success' title={`View ${order.order_number}`}>View</Link> &nbsp;&nbsp;
                                                        <Link to={`/admin/order/${order.id}`} className='btn btn-xs btn-outline-info' title={`Update ${order.order_number}`}>Update</Link>
                                                    </td>
                                                </tr>)}

                                                {orders.length == 0 && <tr><td colSpan={7} className='text-center'>No records found!</td></tr>}
                                            </tbody>
                                        </table>
                                        {/* Pagination Controls */}
                                        <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ResumeOrders;