import React, { useState, useEffect, useRef } from "react";
import countries from "../config/countries"; // Separate file for country list
import languages from "../config/languages"; // Separate file for language list
import axiosInstance from "../config/axiosInstance";
import CustomAlertMessage from "./partials/CustomAlertMessage";
import { useAuth } from "../config/AuthContext";
import AdminSideBar from "./AdminSideBar";
const socialPlatforms = ["Facebook", "Twitter", "Instagram", "LinkedIn", "GitHub"];
const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL

function Profile() {
  const fileInputRef = useRef(null);
  const { updateAuthUser } = useAuth();
  const [userData, setUserData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    bio: "",
    date_of_birth: "",
    phone_number: "",
    gender: "",
    address: "",
    country: "",
    city: "",
    website: "",
    language: "",
    profile_picture: null,
    profile_picture_preview: null,
    social_accounts: [],
  });

  const [alert, setAlert] = useState({
    type: "info",
    message: "",
    display: false,
  });

  const [dbProfilePic, setDBProfilePic] = useState(null)


  // Fetch user profile from API
  useEffect(() => {
    fetchProfile()
  }, []);

  const fetchProfile = async () => {
    try {
      const { data } = await axiosInstance.get("/user/profile/");
      const { profile } = data
      if (profile.profile_picture) {
        setDBProfilePic(`${serverBaseUrl}${profile.profile_picture}`)
      } else {
        setDBProfilePic(null)
      }

      setUserData({ ...profile, profile_picture: null, profile_picture_preview: null })
    } catch (error) {
      const { response: { data: { message } } } = error
      setAlert({ type: "warning", display: true, message });
    } finally {

    }
  }

  // Handle profile picture upload
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUserData({
        ...userData,
        profile_picture: file,
        profile_picture_preview: URL.createObjectURL(file),
      });
    }
  };

  // Handle text input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  // Add new social media account
  const addSocialAccount = () => {
    setUserData({
      ...userData,
      social_accounts: [...userData.social_accounts, { platform: "", profile_url: "" }],
    });
  };

  // Remove a social media account
  const removeSocialAccount = (index) => {
    const updatedAccounts = userData.social_accounts.filter((_, i) => i !== index);
    setUserData({ ...userData, social_accounts: updatedAccounts });
  };

  const handleSocialAccountChange = (index, field, value) => {
    const updatedAccounts = [...userData.social_accounts];

    // Check for duplicates before updating
    if (field === "platform") {
      const isDuplicate = updatedAccounts.some(
        (account, i) => account.platform === value && i !== index
      );
      if (isDuplicate) {
        updatedAccounts[index]["error"] = "This platform is already added.";
        setUserData({ ...userData, social_accounts: updatedAccounts });
        return;
      }
    }

    if (field === "profile_url") {
      const isDuplicate = updatedAccounts.some(
        (account, i) => account.profile_url === value && i !== index
      );
      if (isDuplicate) {
        updatedAccounts[index]["error"] = "This URL is already added.";
        setUserData({ ...userData, social_accounts: updatedAccounts });
        return;
      }
    }

    updatedAccounts[index][field] = value;

    // Ensure a valid URL is provided
    if (field === "profile_url") {
      const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/.*)?$/;
      updatedAccounts[index]["error"] = urlPattern.test(value) ? "" : "Please enter a valid URL.";
    }

    // Ensure platform has a URL
    if (field === "platform" && value !== "" && !updatedAccounts[index]["profile_url"]) {
      updatedAccounts[index]["error"] = "Please provide a valid URL for the selected platform.";
    }

    setUserData({ ...userData, social_accounts: updatedAccounts });
  };


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const social_accounts = userData["social_accounts"] || []
    const updated_social_accounts = social_accounts.filter(item => item.platform !== "")
    setAlert({ type: "info", display: true, message:"Sending data , please wait...." });
    Object.keys(userData).forEach((key) => {
      const value = userData[key];
      // Exclude profile_picture and profile_picture_preview if they are "null" or null
      if ((key === "profile_picture" || key === "profile_picture_preview") && (value === "null" || value === null)) {
        return;
      }
      if (key === "social_accounts") {
        formData.append(key, JSON.stringify(updated_social_accounts));
      } else {
        formData.append(key, value);
      }
    });

    if (updated_social_accounts.length > 0) {
      let errors = updated_social_accounts.filter(item => item.error && item.error !== "").map(account => account.platform)
      if (errors.length > 0) {
        let message = `Provide valid profile urls for the selected social media accounts, ${errors.join(" ")}`
        return setAlert({ type: "danger", display: true, message });
      }

    }

    try {
      const { data } = await axiosInstance.post("/user/profile/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      const { message, userProfile } = data
      if (userProfile.profile_picture) {
        setDBProfilePic(`${serverBaseUrl}${userProfile.profile_picture}`)
      } else {
        setDBProfilePic(null)
      }
      setAlert({ type: "success", display: true, message });
      if (userProfile.profile_picture && localStorage.getItem("user")) {
        const updatedUser = JSON.parse(localStorage.getItem("user"))
        updatedUser['profile_picture_url'] = userProfile.profile_picture
        localStorage.setItem("user", JSON.stringify(updatedUser));
        updateAuthUser(updatedUser)
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred. Please try again.";
      if (error.response) {
        // API responded with an error
        errorMessage = error.response.data?.message || "Something went wrong with the request.";
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage = "No response from the server. Check your internet connection.";
      } else {
        // Something else happened
        errorMessage = error.message;
      }
      setAlert({ type: "danger", display: true, message: errorMessage });
    } finally {

    }
  };

  const removeSelectedImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setUserData(prev => ({
      ...prev,
      profile_picture: null,
      profile_picture_preview: null,
    }))
  }

  return (
    <div className="row">
      <div className="col-md-2">
        <AdminSideBar />
      </div>

      <div className="col-md-8">
        <div className="container">
          <h1>Manage Profile</h1>
          <CustomAlertMessage alertType={alert.type} showAlertMessage={alert.display} alertMessage={alert.message} />
          <div className="card">
            <div className="card-body">
              <form className="form" onSubmit={handleSubmit} method="POST" encType="multipart/form-data">

                {/* Profile Picture Upload */}
                <div className="row mt-3">
                  <div className="form-group">
                    <label>Profile Picture</label>
                    <input type="file" ref={fileInputRef} className="form-control" onChange={handleProfilePictureChange} />
                    <div className="row">
                    {dbProfilePic && (
                      <div className="mt-2 col-md-3">
                        <small className="text-info"> Your current profile picture</small> <br />
                        <img
                          src={dbProfilePic}
                          alt="Profile Preview"
                          className="img-thumbnail mt-1"
                          width="150"
                        />
                      </div>
                    )}
                    {userData.profile_picture_preview && (
                      <div className="mt-2 col-md-3 text-center">
                        <small>Your new selected profile picture.</small> <br />
                        <img
                          src={userData.profile_picture_preview}
                          alt="Profile Preview"
                          className="img-thumbnail mt-2"
                          width="150"
                        /> <br />
                        <button className="btn btn-sm btn-outline-danger mt-2" type="button" onClick={(e) => removeSelectedImage()}>Remove Image</button>
                      </div>
                    )}

                    </div>


                  </div>

                </div>
                {/* Personal Details */}
                <div className="row mt-3">
                  <div className="form-group col-md-4">
                    <label>First Name</label>
                    <input type="text" name="first_name" value={userData.first_name} onChange={handleChange} className="form-control" required />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Middle Name</label>
                    <input type="text" name="middle_name" value={userData.middle_name} onChange={handleChange} className="form-control" />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Last Name</label>
                    <input type="text" name="last_name" value={userData.last_name} onChange={handleChange} className="form-control" required />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="form-group col-md-4">
                    <label>Date of birth</label>
                    <input type="date" name="date_of_birth" value={userData.date_of_birth} onChange={handleChange} className="form-control" required />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Gender</label>
                    <select id="gender" name="gender" className="form-control" required value={userData.gender} onChange={handleChange}>
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Non-Binary">Non-Binary</option>
                      <option value="Transgender">Transgender</option>
                      <option value="Genderqueer">Genderqueer</option>
                      <option value="Agender">Agender</option>
                      <option value="Two-Spirit">Two-Spirit</option>
                      <option value="Other">Other</option>
                      <option value="PreferNotToSay">Prefer Not to Say</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Phone number</label>
                    <input type="text" name="phone_number" value={userData.phone_number} onChange={handleChange} className="form-control" required />
                  </div>
                </div>

                {/* Bio */}
                <div className="row mt-3">
                  <div className="form-group col-md-4">
                    <label>Bio</label>
                    <textarea name="bio" value={userData.bio} onChange={handleChange} className="form-control" required />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Address</label>
                    <textarea name="address" value={userData.address} onChange={handleChange} className="form-control" />
                  </div>

                  <div className="form-group col-md-4">
                    <label>Website URL</label>
                    <input type="text" name="website" value={userData.website} onChange={handleChange} className="form-control" required />
                  </div>

                </div>
                {/* Country & Language Selection */}
                <div className="row mt-3">
                  <div className="form-group col-md-4">
                    <label>Country</label>
                    <select name="country" value={userData.country} onChange={handleChange} required className="form-control">
                      <option value="">Select a country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label>City</label>
                    <input type="text" name="city" value={userData.city} onChange={handleChange} className="form-control" required />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Preferred Language</label>
                    <select name="language" value={userData.language} onChange={handleChange} className="form-control" required>
                      <option value="">Select a language</option>
                      {languages.map((lang, index) => (
                        <option key={index} value={lang.name}>{lang.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Social Media Accounts */}
                <div className="form-group mt-3">
                  <label>Social Media Accounts</label> &nbsp;
                  {userData.social_accounts.map((account, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <select
                        value={account.platform}
                        onChange={(e) => handleSocialAccountChange(index, "platform", e.target.value)}
                        className="form-control me-2"
                      >
                        <option value="">Select Platform</option>
                        {socialPlatforms.map((platform, i) => (
                          <option key={i} value={platform}>{platform}</option>
                        ))}
                      </select>
                      <input
                        type="text"
                        placeholder="Profile URL"
                        value={account.profile_url}
                        onChange={(e) => handleSocialAccountChange(index, "profile_url", e.target.value)}
                        className="form-control me-2"
                      />
                      {account.error && <small style={{ color: "red" }}>{account.error}</small>}
                      <button type="button" className="btn btn-danger" onClick={() => removeSocialAccount(index)}>Remove</button>
                    </div>
                  ))}
                  <button type="button" className="btn btn-primary mt-2" onClick={addSocialAccount}>+ Add Social Account</button>
                </div>

                {/* Submit Button */}
                <button type="submit" className="btn btn-success mt-3">Save Profile</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Profile;
