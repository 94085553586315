import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdminSideBar from "../AdminSideBar";
import CustomAlertMessage from "../partials/CustomAlertMessage";
import axiosInstance from "../../config/axiosInstance";

const QuestionAnswerOptions = () => {
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
        "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
        "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia",
        "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo (Congo-Brazzaville)", "Costa Rica",
        "Croatia", "Cuba", "Cyprus", "Czechia", "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador",
        "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini (Swaziland)", "Ethiopia", "Fiji", "Finland", "France",
        "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
        "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland",
        "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait",
        "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
        "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico",
        "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar (Burma)", "Namibia", "Nauru",
        "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman",
        "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
        "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe",
        "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
        "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
        "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
        "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City",
        "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
      ];
      
      

    const { questionId } = useParams()

    const [question, setQuestion] = useState({ "question": "", "answer_input_field_type": "" })
    const [inputs, setInputs] = useState([""]);
    const [options, setOptions] = useState([]);

    const updateCountries = () =>{
        const country = question.question.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
        
        if(country == "country"){
            setInputs(countries)
        }
        
    }

    const handleChange = (index, event) => {
        const values = [...inputs];
        values[index] = event.target.value;
        setInputs(values);
    };

    const handleAddInput = () => {
        setInputs([...inputs, ""]);
    };

    const handleDeleteInput = (index) => {
        const values = [...inputs];
        values.splice(index, 1);
        setInputs(values);
    };

    useEffect(() => {
        fetchQuestion()
    }, [])

    const fetchQuestion = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data: { data, options } } = await axiosInstance.get(`/admin/questions/${questionId}/`)
            
            setOptions(options)
            setQuestion(data)
            const country = question.question.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
        
            if(country == "country"){
                if(options.length <5){
                    updateCountries()
                }
            }
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        const choices = inputs.filter(item => item != "")
        if (choices.length == 0) {
            return setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Add atleast one answer for the user to select!" })
        }
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Updating data.... please wait!" })
        try {
            const formData = {
                questionId,
                choices
            }
            const { data:{data, message} } = await axiosInstance.post(`/admin/question-options/`, formData)
            setInputs([""])
            setOptions(prev=>([...prev,...data]))
            setAlertMessage({ "type": "success", display: true, isLoading: true, message })
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    const deleteAnswerOption = async (option) => {
        if(!window.confirm(`Delete ${option.option_text}`)) return
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Deleting data.... please wait!" })
        try {

            const {data} = await axiosInstance.delete(`/admin/question-options/${option.id}/`)
            setOptions(prev=>prev.filter(item=>item.id!=option.id))
            setAlertMessage({ "type": "success", display: true, isLoading: true,message: "Answer option deleted successfully!" })
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    return (
        <div className="container-fluid">

            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10 mt-1">
                    <div className="card mt-2  mr-2" >

                        <div className="card-body">
                            <h5 className="card-title mb-3">Update Question #{questionId}</h5>
                            <CustomAlertMessage alertType={alertMessage.type}
                                showAlertMessage={alertMessage.display}
                                alertMessage={alertMessage.message}
                            />

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <form className="form row" onSubmit={handleSubmit}>
                                        <div className="form-group col-md-12">
                                            <p><small>Question</small> {question.question}</p>
                                            <p><small>Answer option type</small> {question.answer_input_field_type}</p>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                {inputs.map((input, index) => (
                                                    <div className="d-flex align-items-center gap-2 mt-2" key={index}>
                                                        <input
                                                            key={index}
                                                            type="text"
                                                            value={input}
                                                            onChange={(event) => handleChange(index, event)}
                                                            className="form-control mt-2 w-75"
                                                            placeholder={`Input answer ${index + 1}`}
                                                        />
                                                        {
                                                            index > 0 && <button
                                                                type="button"
                                                                onClick={() => handleDeleteInput(index)}
                                                                className="btn btn-outline-warning btn-sm mt-3 rounded "
                                                            >{`Remove input answer ${index + 1}`}</button>
                                                        }

                                                    </div>
                                                ))}
                                                <div>
                                                    <button
                                                        type="button"
                                                        onClick={handleAddInput}
                                                        className="btn btn-outline-secondary btn-sm mt-3 rounded "
                                                    >
                                                        Add more answers
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <button className="btn btn-sm btn-outline-success mt-3">Submit</button> &nbsp;&nbsp;&nbsp;
                                            <Link to={`/admin/questions`} className="btn btn-sm btn-outline-secondary mt-3">Go to questions</Link>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <h6>Below are the answer options provided for this question answer</h6>
                                    <ul className="list-group">
                                        {options.map(item => <li className="list-group-item" key={item.id}>#{item.id} {item.option_text} &nbsp;&nbsp; &nbsp;
                                            <button onClick={()=>deleteAnswerOption(item)} title={`Delete: ${item.option_text}`} className="btn btn-xs btn-outline-danger">Delete</button></li>)}
                                    </ul>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default QuestionAnswerOptions;
