function PaginationControls ({handlePageChange,currentPage,totalPages}){
    return<>
    {/* Pagination Controls */}
    <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
        <button
            type="button"
            className="btn"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
                margin: "2px",
                padding: "5px 10px",
                backgroundColor: "#f0f0f0",
                color: "#000",
                border: "1px solid #ccc",
                cursor: "pointer",
            }}
        >
            Prev
        </button>

        {Array.from({ length: totalPages }, (_, index) => (
            <button
                type="button"
                className="btn"
                key={index}
                onClick={() => handlePageChange(index + 1)}
                style={{
                    margin: "2px",
                    padding: "5px 10px",
                    backgroundColor: currentPage === index + 1 ? "#007BFF" : "#f0f0f0",
                    color: currentPage === index + 1 ? "#fff" : "#000",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                }}
            >
                {index + 1}
            </button>
        ))}

        <button
            type="button"
            className="btn"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
                margin: "2px",
                padding: "5px 10px",
                backgroundColor: "#f0f0f0",
                color: "#000",
                border: "1px solid #ccc",
                cursor: "pointer",
            }}
        >
            Next
        </button>
    </div>
</>
}


export default PaginationControls