import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import { Link, useParams } from 'react-router-dom';
import FormatDate from '../partials/FormatDate';
import { server_base_url } from '../partials/ServiceRow';
function ViewOrder() {

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })


    const { orderId } = useParams()

    const [order, setOrder] = useState({})
    const [orderItems, setOrderItems] = useState([])
    const [groupQuestions, setGroupQuestions] = useState([])
    const [questions, setQuestions] = useState([])

    const [resume, setResume] = useState(null)


    useEffect(() => {
        fetchOrder()

    }, [])



    const fetchOrder = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data } = await axiosInstance.get(`/admin/orders/${orderId}/`)
            const { order, order_items, group_questions, questions, resume } = data
            if (resume) {
                setResume(resume)
            }
            console.log({ resume })
            setQuestions(questions)
            setGroupQuestions(groupByGroupName(group_questions))
            console.log(groupByGroupName(group_questions))
            setOrderItems(order_items)
            setOrder(order)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    function groupByGroupName(data) {
        const groupedData = data.reduce((acc, item) => {
            let group = acc.find(g => g.group_name === item.group_name);
            if (!group) {
                group = { group_name: item.group_name, questions: [] };
                acc.push(group);
            }
            group.questions.push(item);
            return acc;
        }, []);

        return groupedData;
    }




    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className='col-md-10 mt-1'>
                    <div className='card border-success mt-2 ml-2 '>
                        <div className='card-body'>
                            <div className='col-md-12'>
                                <h5 className="card-title ">Order information #{order.id}</h5>
                                <Link className='btn btn-outline-secondary btn-sm' to={'/admin/orders'}>Go back to orders list</Link>
                                <CustomAlertMessage alertType={alertMessage.type}
                                    showAlertMessage={alertMessage.display}
                                    alertMessage={alertMessage.message}
                                />

                                <div className='row mt-4'>
                                    <div className='col-md-12 col-sm-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <table className='table table-borderless'>
                                                    <thead>
                                                        <tr>
                                                            <th>Order Number</th>
                                                            <th>Customer email</th>
                                                            <th>Order Status</th>
                                                            <th>Price</th>
                                                            <th>Created At</th>
                                                            <th>Updated At</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {order.order_number}
                                                            </td>
                                                            <td>
                                                                {order?.user?.email}
                                                            </td>
                                                            <td>
                                                                {order.status}
                                                            </td>
                                                            <td>
                                                                ${order.recorded_cost}
                                                            </td>
                                                            <td>
                                                                <FormatDate dateString={order?.created_at} />
                                                            </td>
                                                            <td>
                                                                <FormatDate dateString={order?.updated_at} />
                                                            </td>
                                                            <td>
                                                                <Link className='btn btn-outline-info btn-sm' to={`/admin/order/${orderId}`}>Update order </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-sm-12 mt-2'>

                                        <div className='card'>
                                            <div className='card-body'>
                                                <table className='table table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th>Service name</th>
                                                            <th>Plan name</th>
                                                            <th>Plan title</th>
                                                            <th>Plan description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderItems.map(item => <tr key={item.id}>
                                                            <td>{item.service_plan.service_title}</td>
                                                            <td>{item.service_plan.name}</td>
                                                            <td>{item.service_plan.title}</td>
                                                            <td>{item.service_plan.description}</td>
                                                        </tr>)}
                                                    </tbody>
                                                </table>
                                                {resume && <a target='_blank' href={server_base_url + resume.resume}>Download provided resume</a>}
                                            </div>
                                        </div>

                                    </div>

                                    {
                                        questions.length > 0 && <div className='col-md-6 col-sm-12 mt-2'>
                                            <div className='card text-dark bg-light'>
                                                <div className='card-body'>
                                                    <h6>Questions with Answers provided by user</h6>
                                                    <ul className='list-group'>
                                                        {questions.map(question => <li key={question.id} className='list-group-item'>
                                                            <small style={{ fontWeight: 'bold' }}>{question?.question}</small> <br />
                                                            <small>{question?.answer}</small>
                                                        </li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        groupQuestions.length > 0 && <div className='col-md-6 col-sm-12 mt-2'>
                                            <div className='card text-dark bg-light'>
                                                <div className='card-body'>
                                                    <h6>Questions with Answers provided by user</h6>
                                                    {groupQuestions.map(group => <div key={group.group_name} className='card mt-3'>
                                                        <div className='card-body'>
                                                            <h6>{group.group_name}</h6>
                                                            {group.questions.map(question => <span key={question.id} className='list-group-item'>
                                                                <small style={{ fontWeight: 'bold' }}>{question?.question}</small> &nbsp; &nbsp;
                                                                <small>{question?.answer}</small>
                                                            </span>)}
                                                        </div>
                                                    </div>)}
                                                </div></div>

                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ViewOrder;