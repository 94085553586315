import { Routes, Route } from 'react-router-dom';
import ServicesIndex from "../admin/Services/ServicesIndex";
import ViewService from "../admin/Services/ViewService";
import AdminIndex from "../admin/AdminIndex"
import Login from "../auth/Login";
import CreateModuleLesson from "../admin/ServiceOfferingModule/CreateModuleLesson";
import ServicePlan from "../admin/ServicePlans/ServicePlan";
import EditServicePlan from "../admin/ServicePlans/EditServicePlan";
import CreateServicePlan from "../admin/ServicePlans/CreateServicePlan";
import CreateServiceOffering from "../admin/Services/CreateServiceOffering";
import PlanValueProposition from "../admin/PlanValueProposition/PlanValueProposition";
import LessonContent from "../admin/ServiceOfferingModule/LessonContent";
import ContentIndex from "../admin/ServiceOfferingModule/ContentIndex";
import PrivateRoute from './PrivateRoute';
import NotFound from '../admin/NotFound';
import OrdersIndex from '../admin/Orders/OrdersIndex'
import OrderItems from '../admin/Orders/OrderItems';
import SubscriptionsIndex from '../admin/Subscriptions/SubscriptionsIndex';
import SubscriptionItems from '../admin/Subscriptions/SubscriptionItems';
import UpdateSubscription from '../admin/Subscriptions/UpdateSubscription';
import UpdateOrder from '../admin/Orders/UpdateOrder';
import ViewOrder from '../admin/Orders/ViewOrder';
import Profile from '../admin/Profile';
import ResumeQuestionsIndex from '../admin/ResumeQuestions/ResumeQuestionsIndex';
import EditResumeQuestion from '../admin/ResumeQuestions/EditResumeQuestion';
import CreateResumeQuestion from '../admin/ResumeQuestions/CreateResumeQuestion';
import QuestionAnswerOptions from '../admin/ResumeQuestions/QuestionAnswerOptions';
import CreateResumeQuestionGroup from '../admin/ResumeQuestionGroups/CreateResumeQuestionGroup';
import ResumeQuestionGroupIndex from '../admin/ResumeQuestionGroups/ResumeQuestionGroupIndex';
import UpdateResumeQuestionGroup from '../admin/ResumeQuestionGroups/UpdateResumeQuestionGroup';
import QuestionsGroupIndex from '../admin/QuestionsGroups/QuestionsGroupIndex';
import CreateQuestionGroup from '../admin/QuestionsGroups/CreateQuestionGroup';
import UserResumeQuestions from '../admin/Services/ResumeWriting/UserResumeQuestions';
import CreateUserResumeQuestions from '../admin/Services/ResumeWriting/CreateUserResumeQuestions';
import ResumeOrders from '../admin/Orders/ResumeOrders';
import UserGroups from '../admin/Authorization/UserCroups';
import Users from '../admin/Authorization/Users';

const AppRoutes = () => {
    return <Routes>

        <Route path="/" element={
            <PrivateRoute>
                <AdminIndex />
            </PrivateRoute>
        } />

        <Route path="/admin" element={
            <PrivateRoute>
                <AdminIndex />
            </PrivateRoute>
        } />

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/admin/services" element={
            <PrivateRoute>
                <ServicesIndex />
            </PrivateRoute>
        } />
        <Route path="/admin/content" element={
            <PrivateRoute>
                <ContentIndex />
            </PrivateRoute>
        } />
        <Route path="/admin/create-service" element={<PrivateRoute>
            <CreateServiceOffering />
        </PrivateRoute>} />
        <Route path="/admin/plan-value-proposition" element={<PrivateRoute>
            <PlanValueProposition />
        </PrivateRoute>} />
        <Route path="/admin/service/:id" element={<PrivateRoute>
            <ViewService />
        </PrivateRoute>} />
        <Route path="/admin/service-plan/create" element={<PrivateRoute>
            <CreateServicePlan />
        </PrivateRoute>} />
        <Route path="/admin/service-plan" element={<PrivateRoute>
            <ServicePlan />
        </PrivateRoute>} />

        <Route path="/admin/orders" element={<PrivateRoute>
            <OrdersIndex />
        </PrivateRoute>} />

        <Route path="/admin/resume-writing-orders" element={<PrivateRoute>
            <ResumeOrders />
        </PrivateRoute>} />

        

        <Route path="/admin/order-items" element={<PrivateRoute>
            <OrderItems />
        </PrivateRoute>} />

        <Route path="/admin/subscriptions" element={<PrivateRoute>
            <SubscriptionsIndex />
        </PrivateRoute>} />

        <Route path="/admin/subscription-items" element={<PrivateRoute>
            <SubscriptionItems />
        </PrivateRoute>} />

        <Route path="/admin/edit-service-plan/:serviceId" element={<PrivateRoute>
            <EditServicePlan />
        </PrivateRoute>} />

        <Route path="/admin/subscription/:subscriptionId" element={<PrivateRoute>
            <UpdateSubscription />
        </PrivateRoute>} />

        <Route path="/admin/order/:orderId" element={<PrivateRoute>
            <UpdateOrder />
        </PrivateRoute>} />


        <Route path="/admin/order/view/:orderId" element={<PrivateRoute>
            <ViewOrder />
        </PrivateRoute>} />


        <Route path="/admin/course/:serviceId/create-service-offering-module/:serviceOfferingId" element={<PrivateRoute>
            <CreateModuleLesson />
        </PrivateRoute>} />
        <Route path="/admin/course/:courseId/topic/:topicId/lesson/:lessonId/content" element={<PrivateRoute>
            <LessonContent />
        </PrivateRoute>} />

        <Route path="/profile" element={<PrivateRoute>
            <Profile />
        </PrivateRoute>} />

        <Route path="/admin/questions" element={<PrivateRoute>
            <ResumeQuestionsIndex />
        </PrivateRoute>} />
        <Route path="/admin/question/edit/:questionId" element={<PrivateRoute>
            <EditResumeQuestion />
        </PrivateRoute>} />

        <Route path="/admin/question/create" element={<PrivateRoute>
            <CreateResumeQuestion />
        </PrivateRoute>} />

        <Route path="/admin/question/answer/options/:questionId" element={<PrivateRoute>
            <QuestionAnswerOptions />
        </PrivateRoute>} />

        <Route path="/admin/resume-question-group/create" element={<PrivateRoute>
            <CreateResumeQuestionGroup />
        </PrivateRoute>} />

        <Route path="/admin/resume-question-group-names" element={<PrivateRoute>
            <ResumeQuestionGroupIndex />
        </PrivateRoute>} />

        <Route path="/admin/resume-question-group/:groupId" element={<PrivateRoute>
            <UpdateResumeQuestionGroup />
        </PrivateRoute>} />

        <Route path="/admin/match-resume-questions-to-group" element={<PrivateRoute>
            <QuestionsGroupIndex />
        </PrivateRoute>} />

        <Route path="/admin/match-resume-questions-to-group/create" element={<PrivateRoute>
            <CreateQuestionGroup />
        </PrivateRoute>} />

        <Route path="/admin/create-service-questions/:serviceId" element={<PrivateRoute>
            <CreateUserResumeQuestions />
        </PrivateRoute>} />

        <Route path="/admin/view-service-questions/:serviceId" element={<PrivateRoute>
            <UserResumeQuestions />
        </PrivateRoute>} />

        <Route path="/admin/groups" element={<PrivateRoute>
            <UserGroups />
        </PrivateRoute>} />

        <Route path="/admin/users" element={<PrivateRoute>
            <Users />
        </PrivateRoute>} />

        

    </Routes>
}

export default AppRoutes;