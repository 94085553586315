import React,{useState} from 'react';
import PaginationControls from '../partials/PaginationControls';
import FormatDate from '../partials/FormatDate';
import { Link } from 'react-router-dom';
function SubscriptionsTable({subscriptions}){
    // Pagination state

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the indices for the data to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subscriptions.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(subscriptions.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function getDaysBetween(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    if (isNaN(start) || isNaN(end)) {
        return "Invalid date";
    }

    const differenceInTime = end - start; // Difference in milliseconds
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24); // Convert to days

    return Math.abs(differenceInDays); // Return absolute value
}


    return <>
    <table className='table mt-2 table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order Number</th>
                      <th>Subscription Status</th>
                      <th>Subscription Start Date</th>
                      <th>Subscription End Date</th>
                      <th>No.of days</th>
                      <th>Customer</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map(subscription => <tr key={subscription.id}>
                      <td>{subscription.id}</td>
                      <td>{subscription?.order?.order_number}</td>
                      <td>{subscription.status == "PENDING_ADMIN_CONFIRMATION" ? <button className='btn btn-xs btn-warning'>{subscription.status}</button> : subscription.status}</td>
                      <td><FormatDate dateString={subscription.start_date} /></td>
                      <td><FormatDate dateString={subscription.end_date} /></td>
                      <td>{getDaysBetween(subscription.start_date,subscription.end_date)}</td>
                      <td>{subscription.user.email}</td>
                      <td><FormatDate dateString={subscription.created_at} /></td>
                      <td><FormatDate dateString={subscription.updated_at} /></td>
                      <td><Link to={`/admin/subscription/${subscription.id}`} className='btn btn-xs btn-outline-info' title={`update ${subscription?.order?.order_number} subscription`}>update</Link></td>
                    </tr>)}

                    {subscriptions.length == 0 && <tr><td colSpan={8} className='text-center'>No records found!</td></tr>}
                  </tbody>
                </table>
                {/* Pagination Controls */}
                <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
    </>
}


export default SubscriptionsTable