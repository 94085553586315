import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormatDate from '../partials/FormatDate';
import AdminSideBar from "../AdminSideBar";
import CustomAlertMessage from "../partials/CustomAlertMessage";
import axiosInstance from "../../config/axiosInstance";

const ResumeQuestionsIndex = () => {
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const [questions, setQuestions] = useState([])

    useEffect(() => {
        fetchQuestions()
    }, [])

    const fetchQuestions = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data: { data } } = await axiosInstance.get(`/admin/questions/`)
            setQuestions(data)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    const deleteQuestion = async ({question,id})=>{
        if(!window.confirm(`Are you sure you want to delete this question? ${question}`)){
            return
        }
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Updating data.... please wait!" })
        try {
            const { data } = await axiosInstance.delete(`/admin/questions/${id}/`)
            setAlertMessage({ "type": "success", display: true, isLoading: true, message: "Question has been deleted successfully!" })
            setQuestions(prev=>prev.filter((item)=>item.id!=id))
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    return (
        <div className="container-fluid">

            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10 mt-1">
                    <div className="card mt-2  mr-2" >

                        <div className="card-body">
                            <h5 className="card-title mb-3">Questions</h5>
                            <Link to={"/admin/question/create"} className="btn btn-outline-primary" style={{position:"absolute", top:"10px" ,right:"50px"}} >Create question</Link>
                            <CustomAlertMessage alertType={alertMessage.type}
                                showAlertMessage={alertMessage.display}
                                alertMessage={alertMessage.message}
                            />

                            <table className="table mt-2 table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>Input Type</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questions.map(item => <tr key={item.id} >
                                        <td>{item.id}</td>
                                        <td>{item.question}</td>
                                        <td>{item.answer_input_field_type} <br/> {item.answer_input_field_type == "SELECT_OPTIONS" &&<><Link className="btn btn-sm btn-outline-success mt-2" title={`Add answer options to this question : ${item.question}`}
                                         to={`/admin/question/answer/options/${item.id}`}>Manage answer options</Link> &nbsp;&nbsp;</>}</td>
                                        <td><FormatDate dateString={item.created_at} /></td>
                                        <td><FormatDate dateString={item.updated_at} /></td>
                                        <td>
                                            <Link className="btn btn-sm btn-outline-info" to={`/admin/question/edit/${item.id}`}>Edit</Link> &nbsp;&nbsp;
                                            
                                            
                                            <button className="btn btn-sm btn-outline-danger" onClick={()=>deleteQuestion(item)} type="button" title={`Delete: ${item.question}`}> Delete</button>
                                        </td>
                                    </tr>)}

                                    {questions.length == 0 && <tr><td className="text-center" colSpan={6}> No data found!</td></tr>}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default ResumeQuestionsIndex;
