import React, { useEffect, useState } from 'react'
import AdminSideBar from '../../AdminSideBar'
import axiosInstance from '../../../config/axiosInstance'
import CustomAlertMessage from '../../partials/CustomAlertMessage'
import { Link, useParams } from 'react-router-dom'

function UserResumeQuestions() {

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const { serviceId } = useParams()


    const [questions, setQuestions] = useState([])

    const [service, setService] = useState({ "title": "", "description": "" })

    const [questionsGroups, setQuestionsGroups] = useState([])

    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {

        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data } = await axiosInstance.get(`/service-questions/?service_id=${serviceId}`)
            const { questions, questions_groups, service } = data
            setQuestions(questions)
            setQuestionsGroups(questions_groups)
            setService(service)
            console.log(data)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }



    return <div className="container-fluid">
        <div className="row">
            <div className="col-md-2">
                <AdminSideBar />
            </div>
            <div className='col-md-10'>
                <div className='row'>
                    <h4 className="pt-3">Questions attached to this service</h4>
                    <div className='row'>
                        <div className='col-md-12'>
                            <CustomAlertMessage alertType={alertMessage.type}
                                showAlertMessage={alertMessage.display}
                                alertMessage={alertMessage.message}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>

                            <div className='card'>
                                <div className='card-body'>
                                    <h6>Service details</h6>
                                    <p> <small className='text-info'>Title:</small> {service.title}</p>
                                    <p><small className='text-info'>Description:</small> {service.description}</p>
                                </div>
                            </div>

                            <div className='card mt-2'>

                                <div className='card-body'>
                                    <h6>Individual questions</h6>
                                    <table className='mt-2'>
                                        <tbody>
                                            {questions.map((item, index) => <tr key={index}>
                                                <td>{index += 1}.</td>
                                                <td>{item.text}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-sm-12'>
                            {questionsGroups.map((item) => <div key={item.service_group_question_id} className='card mt-2'>
                                <div className='card-body'>
                                    <h6>{item.name} questions</h6>
                                    <ul>
                                        {item.questions.map(question => <li key={question.question_id}>{question.text}</li>)}
                                    </ul>
                                </div>
                            </div>)}
                        </div>
                        <div className='col-md-12 col-sm-12'>
                            <Link to={`/admin/create-service-questions/${serviceId}`} className="btn btn-outline-secondary mt-2" > click here to attach more questions to the service</Link> &nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div >
}

export default UserResumeQuestions