import React from 'react';

function ServiceForm({ handleFormDataSubmission, handleServiceFormDataChange,formAlertMessage, alertType, setIsEditting, 
    isFormSendingData, serviceFormData , fileInputRef, isEditting}) {
    return <form className='form mt-2 row' onSubmit={handleFormDataSubmission} encType="multipart/form-data">
                <div className="form-group mt-2 col-md-6">
                    <label htmlFor="label" className="label">Title</label>
                    <input type="text" required name='title' className="form-control mt-1" id="form-control"
                        value={serviceFormData.title} onChange={handleServiceFormDataChange} placeholder="Enter placeholder" />
                </div>
                <div className="form-group mt-2 col-md-6">
                    <label htmlFor="label" className="label"> Image</label>
                    <input type="file" name='image' required={isEditting ? false : true} className='form-control mt-1' ref={fileInputRef} />
                    
                </div>
                <div className="form-group mt-2 col-md-12">
                    <label htmlFor="label" className="label">Description</label>
                    <textarea type="text" required name='description' className="form-control mt-1" id="form-control"
                        value={serviceFormData.description} onChange={handleServiceFormDataChange} placeholder="Enter description" />
                </div>

                <div className="form-group mt-2 col-md-6">
                    <label htmlFor="label" className="label">Name Id <small className='text-danger'>(If value exist, do not edit it)</small></label>
                    <input type="text" required name='name_id' className="form-control mt-1" id="form-control"
                        value={serviceFormData.name_id ?? ""} onChange={handleServiceFormDataChange} placeholder="Enter placeholder" />
                </div>
                

                <div className={`alert alert-${alertType} mt-2 ${!isFormSendingData ? "d-none": ""}`}>
                    <small>{formAlertMessage}</small>
                </div>

                <div className={`form-group mt-2 ${isFormSendingData ? "d-none":""}`}>
                    <button className={`btn mt-2 btn-${isEditting?'success':'primary'}`} type='submit'> {isEditting?'Update service' : 'Submit'}</button>
                    &nbsp;&nbsp;<button onClick={()=>setIsEditting(false)} type='button' className={`btn mt-2 btn-outline-secondary ${!isEditting?'d-none':''}`} >Cancel Edits</button>
                </div>
            </form>
}

export default ServiceForm;