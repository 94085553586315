import React, { useEffect, useState } from 'react'
import AdminSideBar from '../../AdminSideBar'
import axiosInstance from '../../../config/axiosInstance'
import CustomAlertMessage from '../../partials/CustomAlertMessage'
import { Link, useParams } from 'react-router-dom'

function CreateUserResumeQuestions() {

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const {serviceId} = useParams()


    const [questions, setQuestions] = useState([])

    const [questionsGroups, setQuestionsGroups] = useState([])

    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {

        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data: { data } } = await axiosInstance.get(`/admin/resume-group-questions/all-questions-groups/`)
            const { questions, resume_group_questions } = data
            const updatedQuestions = questions.map(item => ({ ...item, isChecked: false }))
            setQuestions(updatedQuestions);

            const grouped = groupByQuestionGroup(resume_group_questions)

            setQuestionsGroups(grouped)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    const handleQuestionInputChangeEvent = (e, question, type) => {
        const isChecked = e.target.checked;
        if (type == "question") {
            setQuestions(prev => prev.map(item => {
                if (item.id == question.id) return { ...question, isChecked }
                return item
            }))
        } else if (type == "group") {
            setQuestionsGroups(prev => prev.map(item => {
                if (item.id == question.id) return { ...question, isChecked }
                return item
            }))
        }

    }

    function groupByQuestionGroup(data) {
        const groupedData = data.reduce((acc, item) => {
            const { question_group__id, id, question__answer_input_field_type, question_group__name, question__id, question__question } = item;

            let group = acc.find(g => g.group_id === question_group__id);

            if (!group) {
                group = {
                    group_id: question_group__id,
                    id: id,
                    isChecked: false,
                    group_name: question_group__name,
                    questions: []
                };
                acc.push(group);
            }

            group.questions.push({
                question_id: question__id,
                text: question__question,
                answer_input_file_type: question__answer_input_field_type
            });

            return acc;
        }, []);
        return groupedData;
    }

    const saveQuestions = async ()=>{
        const questions_ = questions.filter(item=>item.isChecked)
        const questions_groups = questionsGroups.filter(item=>item.isChecked)

        if(questions_.length==0){
            return setAlertMessage(prev => ({ ...prev, "display": true, type: "danger", message: "Select ateast on question for the service" }))
        }

        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Saving data.... please wait!" })

        const form_data ={
            questions:questions_.map(item=>item.id),
            questions_groups:questions_groups.map(item=>item.group_id),
            service_id:serviceId
        }

        console.log(form_data)

        try {
            const { data } = await axiosInstance.post(`/service-questions/save-service-questions/`,form_data)
            setAlertMessage(prev => ({ ...prev, type: "success", message:data.message}))
            setTimeout(()=>window.location.reload(),2000)
            console.log(data)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    
    }



    return <div className="container-fluid">
        <div className="row">
            <div className="col-md-2">
                <AdminSideBar />
            </div>
            <div className='col-md-10'>
                <div className='row'>
                    <h4 className="pt-3 text-center">Resume Questions</h4>
                    <p>Select questions and question groups that the user needs to fill when subscribing to this service</p>



                    <div className='row'>
                        <div className='col-md-12'>
                        <CustomAlertMessage alertType={alertMessage.type}
                            showAlertMessage={alertMessage.display}
                            alertMessage={alertMessage.message}
                        />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h6>Select individual questions</h6>
                                    <table className="mt-2">
                                        <tbody>
                                            {questions.map((item, index) => <tr key={item.id}>
                                                <td>
                                                    <div className="form-check">
                                                        <span className='text-muted'>{index += 1}.</span> &nbsp;
                                                        <input className="form-check-input" type="checkbox" value={item.isChecked} onChange={(e) => handleQuestionInputChangeEvent(e, item, "question")} id="flexCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            {item.question}
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h6>Select question groups</h6>
                                    <table className="mt-2">
                                        <tbody>
                                            {questionsGroups.map((item, index) => (<tr key={index += 1}>
                                                <td>
                                                    <div className='card mt-2'>
                                                        <div className='card-body'>
                                                            <h6 className='card-title'>{item.group_name}</h6>
                                                            <div className="form-check">
                                                                <span className='text-muted'>{index += 1}.</span> &nbsp;
                                                                <input className="form-check-input" type="checkbox" value={item.isChecked} onChange={(e) => handleQuestionInputChangeEvent(e, item, "group")} id="flexCheckDefault" />
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    <ul>
                                                                        {item.questions.map(question => (<li key={question.question_id}>{question.text}</li>))}
                                                                    </ul>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mt-2'>
                        <CustomAlertMessage alertType={alertMessage.type}
                            showAlertMessage={alertMessage.display}
                            alertMessage={alertMessage.message}
                        />
                        <button className='btn btn-success mt-2' type='button'  onClick={()=>saveQuestions()}>Save questions to resume services</button>&nbsp;&nbsp;&nbsp;
                        <Link to={`/admin/question/create`} className="btn btn-outline-secondary mt-2" >Create question</Link> &nbsp;&nbsp;&nbsp;
                        <Link to={`/admin/resume-question-group/create`} className="btn btn-outline-secondary mt-2" >Create question group</Link> 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default CreateUserResumeQuestions