import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdminSideBar from "../AdminSideBar";
import CustomAlertMessage from "../partials/CustomAlertMessage";
import axiosInstance from "../../config/axiosInstance";

const EditResumeQuestion = () => {
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const {questionId} = useParams()

    const [ question , setQuestion] = useState({"question":"","answer_input_field_type":""})

    useEffect(() => {
        fetchQuestions()
    }, [])

    const fetchQuestions = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data:{data} } = await axiosInstance.get(`/admin/questions/${questionId}/`)
            setQuestion(data)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    const INPUT_TYPES_CHOICES = ["INPUT_FIELD","TEXT_FIELD","BOOLEAN","FILE","DATE","SELECT_OPTIONS",""]

    const handleSubmit = async (e)=>{
        e.preventDefault()
        const { answer_input_field_type } = question
        const formData = {question:question.question , answer_input_field_type }

        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Updating data.... please wait!" })
        try {
            const { data } = await axiosInstance.put(`/admin/questions/${questionId}/`,formData)
            setAlertMessage({ "type": "success", display: true, isLoading: true, message: data.message })
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    return (
        <div className="container-fluid">

            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10 mt-1">
                    <div className="card mt-2  mr-2" >

                        <div className="card-body">
                            <h5 className="card-title mb-3">Update Question #{questionId}</h5>
                            <CustomAlertMessage alertType={alertMessage.type}
                                showAlertMessage={alertMessage.display}
                                alertMessage={alertMessage.message}
                            />

                            <div className="row">
                                <form className="form row" onSubmit={handleSubmit}>
                                    <div className="form-group col-md-4">
                                        <label className="label" htmlFor="question">Question</label>
                                        <input className="form-control mt-2" value={question.question} onChange={e=>setQuestion(prev=>({...prev,[e.target.name]:e.target.value}))} 
                                        placeholder="Enter question" name="question" required id="question"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="label" htmlFor="answer_input_field_type">Input Answer Type</label>
                                        <select name="answer_input_field_type" required value={question.answer_input_field_type} onChange={e=>setQuestion(prev=>({...prev,[e.target.name]:e.target.value}))} 
                                        id="answer_input_field_type" className="form-control mt-2">
                                            {INPUT_TYPES_CHOICES.map(item=><option value={item} key={item}>{item}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <button className="btn btn-sm btn-outline-success mt-3">Update</button> &nbsp;&nbsp;&nbsp;
                                        <Link to={`/admin/questions`} className="btn btn-sm btn-outline-secondary mt-3">Cancel updates</Link>
                                    </div>
                                </form>
                            </div>

                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default EditResumeQuestion;
