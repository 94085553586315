import React, {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormatDate from '../partials/FormatDate';
import AdminSideBar from "../AdminSideBar";
import CustomAlertMessage from "../partials/CustomAlertMessage";
import axiosInstance from "../../config/axiosInstance";

const ResumeQuestionGroupIndex = () => {
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const [resumeQuestionGroups , setResumeQuestionGroups] = useState([])

    useEffect(()=>{
        fetchData()
    },[])

    const fetchData = async ()=>{
        
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Updating data.... please wait!" })
        try {
            const { data:{data} } = await axiosInstance.get(`/admin/resume-question-groups/`)
            setResumeQuestionGroups(data)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    const deleteQuestionGroup = async ({name,id})=>{
        if(!window.confirm(`Are you sure you want to delete this question group name? ${name}`)){
            return
        }
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Updating data.... please wait!" })
        try {
            const { data } = await axiosInstance.delete(`/admin/resume-question-groups/${id}/`)
            setAlertMessage({ "type": "success", display: true, isLoading: true, message: "Question group has been deleted successfully!" })
            setResumeQuestionGroups(prev=>prev.filter((item)=>item.id!=id))
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }


    return (
        <div className="container-fluid">

            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10 mt-1">
                    <div className="card mt-2  mr-2" >

                        <div className="card-body">
                            <h5 className="card-title mb-3">Resume question group names</h5>
                            <p><Link to={`/admin/match-resume-questions-to-group/create`}>Click here to add questions to a group</Link></p>
                            <Link to={"/admin/resume-question-group/create"} className="btn btn-outline-primary" style={{position:"absolute", top:"10px" ,right:"50px"}} >Create question group</Link>
                            <CustomAlertMessage alertType={alertMessage.type}
                                showAlertMessage={alertMessage.display}
                                alertMessage={alertMessage.message}
                            />

                            <div className="col-md-12">
                                <table className="table mt-2 table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Created At</th>
                                            <th>Updated At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {
                                                resumeQuestionGroups.map(item=><tr key={item.id} >
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td><FormatDate dateString={item.created_at}/></td>
                                                    <td><FormatDate dateString={item.updated_at}/></td>
                                                    <td>
                                                        <Link to={`/admin/resume-question-group/${item.id}`} className="btn btn-xs btn-outline-info" >Update</Link> &nbsp;&nbsp;&nbsp;
                                                        <button onClick={()=>deleteQuestionGroup(item)} className="btn btn-xs btn-outline-danger" type="button">Delete</button>
                                                    </td>
                                                </tr>)
                                            }
                                    </tbody>
                                </table>
                            </div>

                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default ResumeQuestionGroupIndex;
