import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404</h1>
      <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" style={styles.link}>Go Back Home</Link>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
  },
  title: {
    fontSize: "80px",
    fontWeight: "bold",
    color: "#ff6347",
  },
  message: {
    fontSize: "20px",
    marginBottom: "20px",
  },
  link: {
    textDecoration: "none",
    color: "white",
    backgroundColor: "#007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "18px",
  },
};

export default NotFound;
