import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import PaginationControls from '../util/PaginationControls';
import FormatDate from '../partials/FormatDate';

function Users() {

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const [users, setUsers] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 15;


    useEffect(() => {
        fetchUsers(currentPage)
    }, [currentPage])



    const fetchUsers = async (page) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data from database.... please wait!" })
        try {
            const { data: { results, total_pages } } = await axiosInstance.get(`/admin/users/?page=${page}&page_size=${pageSize}`)
            setTotalPages(total_pages)
            setUsers(results)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className='col-md-10 mt-1'>
                    <div className='card mt-2 ml-2 '>
                        <div className='card-body'>
                            <div className='col-md-12'>
                                <h5 className="card-title ">System users </h5>
                                <CustomAlertMessage alertType={alertMessage.type}
                                    showAlertMessage={alertMessage.display}
                                    alertMessage={alertMessage.message}
                                />

                                <div className='row'>

                                    <div className='col-md-12 col-sm-12'>
                                        <table className='table mt-2 table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>FirstName</th>
                                                    <th>LastName</th>
                                                    <th>Is Active</th>
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Date joined</th>
                                                    <th>Groups</th>
                                                    <th>Action</th>
                                                </tr>

                                            </thead>
                                            <tbody>

                                                {users.map((user, index) => <tr key={index+=1}>
                                                    <td>{user.id}</td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>{user.is_active}</td>
                                                    <td>{user.username}</td>
                                                    <td>{user.email}</td>
                                                    <td><FormatDate dateString={user.date_joined} /></td>
                                                    <td>{user?.groups?.map(item => <span key={item.id}>{item?.name}</span>)}</td>
                                                    <td>Coming soon</td>
                                                </tr>)}

                                            </tbody>



                                        </table>

                                        <PaginationControls handlePageChange={handlePageChange} currentPage={currentPage} totalPages={totalPages} />



                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Users;