import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import OrdersTable from './OrdersTable';
import { Link } from 'react-router-dom';

function OrdersIndex() {

  const [alertMessage, setAlertMessage] = useState({
    "type": "info",
    "display": false,
    "message": "",
    "isLoading": false
  })

  const [orders, setOrders] = useState([])

  
  useEffect(() => {
    fetchOrders()
  }, [])



  const fetchOrders = async (e) => {
    setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
    try {
      const { data } = await axiosInstance.get(`/admin/orders/`)
      const { orders } = data
      setOrders(orders)
    } catch (error) {
      setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
    } finally {
      setTimeout(() => {
        setAlertMessage({
          "type": "info",
          "display": false,
          "message": "",
          "isLoading": false
        })

      }, 1000)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <AdminSideBar />
        </div>
        <div className='col-md-10 mt-1'>
          <div className='card mt-2 ml-2 '>
            <div className='card-body'>
              <div className='col-md-12'>
                <h5 className="card-title ">Orders</h5>
                <Link to={"/admin/resume-writing-orders"} className="btn btn-outline-primary" 
                        style={{width:"250px",position:"absolute",right:"50px", top:"20px"}} >Resume writing service orders</Link>
                <small className='mb-2'>System orders , placed by customers</small>
                <CustomAlertMessage alertType={alertMessage.type}
                  showAlertMessage={alertMessage.display}
                  alertMessage={alertMessage.message}
                />



                <OrdersTable orders={orders} />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default OrdersIndex;