import React, { useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar";
import { Link, useParams } from "react-router-dom";
import ServiceOfferingCard from "../Services/ServiceOfferingCard";
import axiosInstance from "../../config/axiosInstance";
import LessonContentForm from "./LessonContentForm";
import ContentTable from "./ContentTable";
import { stripHtmlTags } from "./ContentIndex";


function LessonContent() {
    const { lessonId, topicId, courseId } = useParams()

    const [course, setCourse] = useState(null)
    const [lesson, setLesson] = useState(null)
    const [topic, setTopic] = useState(null)
    const [content, setContent] = useState([])
    const [lessonContent, setLessonContent] = useState([])
    const [displayLessonContent, setDisplayContentLesson] = useState(false)
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    useEffect(() => {
        fetchData()

    }, [])

    const fetchData = async () => {
        setDisplayContentLesson(false)
        setAlertMessage(prev => ({ ...prev, display: true, isLoading: true, message: "Fetching data.... please wait!" }))
        try {
            const { data } = await axiosInstance.get(`/topic-lessons/${lessonId}/?topicId=${topicId}&courseId=${courseId}`)
            const { course, topic, lesson } = data
            setCourse(course)
            setLesson(lesson)
            setTopic(topic)
            const fmtContent = data.content.map(item => ({
                ...item, 
                isChecked: false, 
                name: stripHtmlTags(item.name),
                description: stripHtmlTags(item.description),
                title: stripHtmlTags(item.title),
            }))
            setContent(fmtContent)

        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })
            }, 1000)
        }
    }

    const handleLessonContentForm = async (e) => {
        e.preventDefault()
        setDisplayContentLesson(false)
        const selectedContent = content.reduce((accumulator, currentValue) => {
            if (currentValue.isChecked) {
                return [...accumulator, currentValue.id]
            }
            return accumulator
        }, [])
        const formData = {
            course_content: selectedContent,
            topic_lesson: lessonId
        }
        setAlertMessage(prev => ({ ...prev, display: true, isLoading: true, message: "Submitting form.... please wait!" }))
        try {
            const { data: { message } } = await axiosInstance.post("/lesson-content/", formData)

            setAlertMessage(prev => ({ ...prev, type: "success", message }))
        } catch (error) {
            const { response: { data: { message } } } = error
            setAlertMessage(prev => ({ ...prev, type: "danger", message: message || "Error ocurred , contact administrator." }))
        } finally {
            resetAlertMessage()
        }
    }

    const handleContentInputCheckBox = (item) => {
        setContent(prev => prev.map(content => {
            if (content.id == item.id) {
                return { ...content, isChecked: !content.isChecked }
            }
            return content
        }))
    }

    const resetAlertMessage = () => {
        setTimeout(() => {
            setAlertMessage({
                "type": "info",
                "display": false,
                "message": "",
                "isLoading": false
            })
        }, 1000)
    }

    const viewLessonContent = async () => {
        const d = displayLessonContent ? false :true
        setDisplayContentLesson(prev => !prev)

        if (!d) return

        setAlertMessage(prev => ({ ...prev, display: true, isLoading: true, message: "Fetching module content.... please wait!" }))
        try {
            const { data: { message, data } } = await axiosInstance.get(`/lesson-content/?topic_lesson=${lessonId}`)
            setLessonContent(data.map(item => {
                const course_content = item.course_content
                return {
                    ...course_content,
                    name: stripHtmlTags(course_content.name),
                    description: stripHtmlTags(course_content.description),
                    title: stripHtmlTags(course_content.title),
                }

            } ))
        } catch (error) {
            const { response: { data: { message } } } = error
            setAlertMessage(prev => ({ ...prev, type: "danger", message: message || "Error ocurred , contact administrator." }))
        } finally {
            resetAlertMessage()
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <div className="row">
                        <h4 className="mt-1">Create Content for the module</h4>
                    </div>

                    <button type="button" className={`btn btn-sm btn-outline-${displayLessonContent ? "primary" : "info"}`} onClick={() => viewLessonContent()}>{displayLessonContent ? "Create module content" : "View module content"}</button>

                    <Link to={`/admin/course/${courseId}/create-service-offering-module/${topicId}`} style={{ position: "absolute", right: "40px", top: "63px" }}
                        className="btn btn-sm btn-outline-secondary ">Go back to service offerings</Link>

                    <div className="row mb-4">
                        <div className="col-md-3 col-sm-6">
                            <div className="row">
                                <h6 className="mt-2">#{lesson?.id}. Module Details</h6>
                                <div className="card">
                                    <div className="card-body">
                                        <p>
                                            <small><b>Name</b></small> {lesson?.name}
                                        </p>

                                        <p>
                                            <small><b>Description</b></small> {lesson?.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h6 className="mt-3">Service Offering</h6>
                                <div className="card">
                                    <div className="card-body">
                                        <b>#{topic?.id}.</b> {topic?.plan_value_proposition?.description}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h6 className="mt-3">Service Details</h6>
                                {course ? <ServiceOfferingCard service={course} /> : <div className="alert alert-info mt-2">Course information not found</div>}
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-6">
                            <small>A single lesson can have one or many content. Note: A lesson belongs to a Topic. A topic can have one or many lessons . A topic belongs to a course . A course can have one or many topics </small>
                            <h6 className="mt-1">Fill the form below to create content for this lesson</h6>
                            <div className="row">
                                <div className={`col-md-11 mx-auto alert alert-${alertMessage.type} ${!alertMessage.display ? "d-none" : ""}`}>
                                    {alertMessage.message}
                                </div>
                            </div>
                            <hr />

                            <div className={`row ${!displayLessonContent ? "d-none" : ""} `}>
                                <div className="col-md-12">
                                    <h6 className="text-success">This lesson has the following content attached.</h6>
                                    <ContentTable content={lessonContent} hideActionButtons={true} />
                                </div>
                            </div>

                            <div className={`row ${displayLessonContent ? "d-none" : ""}`}>
                                <LessonContentForm content={content}
                                    handleLessonContentForm={handleLessonContentForm}
                                    handleContentInputCheckBox={handleContentInputCheckBox} />
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
}

export default LessonContent;