import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import TopicInputFieldCheckBox from './TopicInputFieldCheckBox';
import CustomAlertMessage from '../partials/CustomAlertMessage';
import { Link, useParams } from 'react-router-dom';

function EditServicePlan() {
    const { serviceId } = useParams()
    const [alertMessage, setAlertMessage] = useState()
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [alertType, setAlertType] = useState('info')
    const [service, setService] = useState({
        "id": "",
        "description": "",
        "service_offerings": []
    })


    const [servicePlans, setServicePlans] = useState([])
    useEffect(() => {
        fetchServices()
    }, [])


    const fetchServices = async () => {
        displayAlertMessage("Fetching service information from database. please wait...", 'info')
        try {
            const { data } = await axiosInstance.get(`/service-plans/service/${serviceId}/plans`)
            setServiceAndServicePlans(data)
        } catch (error) {
            displayAlertMessage("Error ocurred, contact admin", 'danger')
        } finally {
            setTimeout(() => hideAlertMessage(), 1000)
        }
    }

    const setServiceAndServicePlans = (data) => {
        const service_offerings = data?.service?.service_offerings || []
        const plans = data?.plans?.map(plan => {
            const s = service_offerings.map(offering => {
                const plan_value_proposition_id = offering.plan_value_proposition.id

                const isPlanPropositionIsFound = plan.plan_value_propositions.find(p => p?.service_offering?.plan_value_proposition?.id == plan_value_proposition_id)

                const isChecked = isPlanPropositionIsFound ? true : false

                return { ...offering, isChecked }
            })
            const selected_service_offerings = plan.plan_value_propositions
            return { ...plan, service_offerings: s, selected_service_offerings }
        }) || []
        setService(data.service)
        
        setServicePlans(plans)
    }

    const displayAlertMessage = (message, alert_type) => {
        setAlertMessage(message)
        setShowAlertMessage(true)
        setAlertType(alert_type)
    }

    const hideAlertMessage = () => {
        setAlertMessage("")
        setShowAlertMessage(false)
        setAlertType("info")
    }


    const removePlanFromUI = async (id) => {

        const plan = servicePlans.find(plan => plan.id == id)
        if (plan) {
            if (!window.confirm(`Please confirm service plan deletion. The plan to be deleted is '${plan.name}'`)) return
            displayAlertMessage(`Deleting plan (${plan.name}) from the service. please wait...`, 'info')

            try {
                await axiosInstance.delete(`/service-plans/${plan.id}/`)
                displayAlertMessage("Service plan was deleted successfully!", 'success')
            } catch (error) {
                displayAlertMessage("Error ocurred, contact admin", 'danger')
            } finally {
                setTimeout(() => hideAlertMessage(), 1000)
            }

            const updatedPlans = servicePlans.filter((plan) => plan.id !== id);

            setServicePlans(updatedPlans);
        } else {
        }


    }

    const handleTopicInputCheckBoxChange = async (offering, plan, isChecked) => {
        
        const selectedOffering = plan.selected_service_offerings.find(item => item.service_offering.id == offering.id)

        displayAlertMessage("Updating service plan. please wait...", 'info')
        try {

            if (isChecked) {
                const formData = { planId: plan.id, offeringId: offering.id }
                const { data } = await axiosInstance.post(`/service-plans/service/${serviceId}/plans/`, formData)
                setServiceAndServicePlans(data)
            } else {
                const formData = { servicePlanPropositionId:selectedOffering.id }
                const { data } = await axiosInstance.delete(`/service-plans/service/${serviceId}/plans/`, {data:formData})
                setServiceAndServicePlans(data)
            }
            const updatedTopics = plan.service_offerings.map(offeringItem => {
                if (offeringItem.plan_value_proposition.id == offering.plan_value_proposition.id) {
                    return { ...offeringItem, isChecked }
                }
                return offeringItem
            })
            const updatedPlan = { ...plan, "card_bg": "bg-light text-black", service_offerings: updatedTopics }
    
            setServicePlans(prev => prev.map(plan => {
                if (plan.id == updatedPlan.id) {
                    return updatedPlan
                }
                return plan
            }))
    
            displayAlertMessage("Service plan update was successful", 'success')

        } catch (error) {
            displayAlertMessage("Error ocurred, contact admin", 'danger')
        } finally {
            setTimeout(() => hideAlertMessage(), 1000)
        }


        


        
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <Link to={"/admin/service-plan"} className="btn btn-outline-secondary"
                        style={{ width: "250px", position: "absolute", right: "50px", marginTop: "10px" }} >Go to Service plans</Link>
                    <h3 className='mt-2'>
                        Edit Service plan
                    </h3>
                    <div className='row'>
                        <div className='col-md-12 col-lg-12'>
                            <CustomAlertMessage alertType={alertType}
                                showAlertMessage={showAlertMessage}
                                alertMessage={alertMessage}
                            />
                        </div>
                        <div className='col-md-12 col-lg-12'>
                            <div className='mt-3 alert alert-secondary'>
                                <p><b>Service Title </b> {service.title}</p>
                                <p><b>Service Description </b>  {service.description}</p>
                                <p><b>Offerings Available</b> {service.service_offerings.length}</p>
                            </div>
                        </div>

                        <div className={`row mt-3`}>
                            <h3 className='mt-1 mb-3'>Defined plans</h3>
                            {servicePlans.length > 0 && servicePlans.map((plan, planIndex) => {

                                return <div key={planIndex} className='col-md-4'>
                                    <div className={`card mb-3 ${plan.card_bg}`}>
                                        <div className='card-body'>
                                            <button type='button' id={plan.id} onClick={() => removePlanFromUI(plan.id)} style={{ position: "absolute", top: "10px", right: "10px" }}
                                                className='btn btn-sm btn-outline-danger' > Remove plan</button>
                                            <p className='mb-0'><b>Name </b> {plan.name} </p>
                                            <p className='mb-0'><b>Title </b> {plan.title} </p>
                                            <p className=''><b>Description </b> {plan.description} </p>
                                            <p className=''><b>Price </b> ${plan.price} </p>
                                            <p className='text-success fw-bold'>Selected service offerings for the plan</p>
                                            <ul className='list-group'>
                                                {plan.service_offerings && plan.service_offerings.map((topic) => <TopicInputFieldCheckBox plan={plan}
                                                    handleTopicInputCheckBoxChange={handleTopicInputCheckBoxChange} key={topic.id} topic={topic} />)}
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            })}
                            {(servicePlans.length == 0 && !showAlertMessage) && <div className='col-md-12 alert alert-info'>
                                This service has no plans defined. Go to <Link to={'/admin/service-plan/create'}>Create service page</Link>. Select the service "{service.title}" & define it's plans </div>}

                        </div>
                        <div className='col-md-12 col-lg-12'>
                            <CustomAlertMessage alertType={alertType}
                                showAlertMessage={showAlertMessage}
                                alertMessage={alertMessage}
                            />
                        </div>
                    </div>


                </div>
            </div>
        </div>

    );
}

export default EditServicePlan