import React from "react";
import Editor from 'react-simple-wysiwyg';
function ContentForm({ handleSubmit, formData, alertMessage, isEdittingContent, setFormData }) {
  const addFile = () => {
    setFormData(prev => ({
      ...prev,
      files: [...(prev.files || []), null]
    }));
  };

  const addUrl = () => {
    setFormData(prev => ({
      ...prev,
      urls: [...(prev.urls || []), ""]
    }));
  };

  const updateFile = (index, file) => {
    setFormData(prev => ({
      ...prev,
      files: prev.files.map((currentFile, i) => (i === index ? file : currentFile))
    }));
  };

  const updateUrl = (index, value) => {
    setFormData(prev => ({
      ...prev,
      urls: prev.urls.map((url, i) => (i === index ? value : url))
    }));
  };

  const removeFile = index => {
    setFormData(prev => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index)
    }));
  };

  const removeUrl = index => {
    setFormData(prev => ({
      ...prev,
      urls: prev.urls.filter((_, i) => i !== index)
    }));
  };

  return (
    <form className="form" onSubmit={handleSubmit} encType="multipart/form-data">
      {/* <div className="form-group">
        <label htmlFor="title" className="label"><h6>Fill in the content title below</h6></label>
        <Editor name="title" id="title"
          value={formData.title}
          onChange={(e)=>setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
          containerProps={{ style: { height: "350px", overflow:"auto" } }} />
      </div> */}
{/* 
      <div className="form-group mt-3">
        <label htmlFor="name" className="label"><h6>Fill in the content name below</h6></label>
        <Editor name="name" id="name"
          value={formData.name}
          onChange={(e)=>setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
          containerProps={{ style: { height: "350px", overflow:"auto" } }} />
      </div> */}

      <div className="form-group mt-2">
        <label htmlFor="description" className="label"><h6>Fill in the content description below</h6></label>
        <Editor name="description" id="description"
          value={formData.description}
          onChange={(e)=>setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
          containerProps={{ style: { height: "550px", overflow:"auto" } }} />
      </div>

      {/* Files Section */}
      <div className="form-group mt-2">
        <label className="label">Files</label>
        {(formData.files || []).map((file, index) => (
          <div key={index} className="d-flex align-items-center mt-1">
            <input
              type="file"
              className="form-control me-2"
              onChange={(e) => updateFile(index, e.target.files[0])} // Storing the file object
            />
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => removeFile(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-sm btn-outline-primary mt-2"
          onClick={addFile}
        >
          Add File
        </button>
      </div>

      {/* URLs Section */}
      <div className="form-group mt-2">
        <label className="label">URLs</label>
        {(formData.urls || []).map((url, index) => (
          <div key={index} className="d-flex align-items-center mt-1">
            <input
              type="text"
              className="form-control me-2"
              value={url}
              placeholder={`URL ${index + 1}`}
              onChange={(e) => updateUrl(index, e.target.value)}
            />
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => removeUrl(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-sm btn-outline-primary mt-2"
          onClick={addUrl}
        >
          Add URL
        </button>
      </div>
      <hr />
      <div className="form-group mt-2">
        <button
          className={`btn btn-sm btn-outline-info mt-1 ${alertMessage?.isLoading ? "d-none" : ""
            }`}
          type="submit"
        >
          {isEdittingContent ? `Update Content #${formData.id}` : "Submit"}
        </button>
      </div>
    </form>
  );
}

export default ContentForm;
