import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

function UpdateSubscription() {


    const { subscriptionId } = useParams()

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const [subscription, setSubscription] = useState({})

    const [formData, setFormData] = useState({
        "subscription_status": "",
        "subscription_start_date": "",
        "subscription_end_date": ""
    })

    const [daysInBetween , setDaysInBetween] = useState("")


    useEffect(() => {
        fetchSubscription()
    }, [])

    useEffect(() => {
        const n = getDaysBetween(formData.subscription_start_date, formData.subscription_end_date)
        
        setDaysInBetween(n)
    }, [formData])

    function formatDateToInput(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split("T")[0];
    }



    const fetchSubscription = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data } = await axiosInstance.get(`/admin/subscriptions/${subscriptionId}/`)
            const subscription = data.subscription || {}

            
            setSubscription(subscription)
            if(subscription.status){
                setFormData(prev=>({...prev,subscription_status:subscription.status}))
            }
            if(subscription.start_date){
                setFormData(prev=>({...prev,subscription_start_date:formatDateToInput(subscription.start_date)}))
            }

            if(subscription.end_date){
                setFormData(prev=>({...prev,subscription_end_date:formatDateToInput(subscription.end_date)}))
            }
            setSubscription(subscription)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    function validateSubscriptionDates(subscription) {
        const { subscription_start_date, subscription_end_date } = subscription;
    
        if (!subscription_start_date || !subscription_end_date) {
            return { isValid: false, message: "Subscription start date and end date are required." };
        }
    
        const startDate = new Date(subscription_start_date);
        const endDate = new Date(subscription_end_date);
    
        if (startDate > endDate) {
            return { isValid: false, message: "Subscription start date cannot be greater than end date." };
        }

        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Submitting data.... please wait!" })
    
        return { isValid: true, message: "Dates are valid." };
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form_data = {...formData, subscriptionId}
        const {isValid,message} = validateSubscriptionDates(formData)

        if(!isValid){
            return setAlertMessage({ "type": "danger", display: true, isLoading: true, message })
        }
        try {
            const { data } = await axiosInstance.post(`/admin/subscriptions/`,form_data)
            const {message} = data
            setAlertMessage(prev => ({ ...prev, type: "success", message }))
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    
    }

    function getDaysBetween(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        if (isNaN(start) || isNaN(end)) {
            return "Invalid date";
        }
    
        const differenceInTime = end - start; // Difference in milliseconds
        const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24); // Convert to days
    
        return Math.abs(differenceInDays); // Return absolute value
    }

    function formatDate(dateString) {
        if (!dateString) return "";
        try {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat("en-US", {
                dateStyle: "medium",
                timeStyle: "short",
            }).format(date);
        } catch (error) {
            return "Invalid Date";
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className='col-md-10 mt-1'>
                    <div className='card mt-2 ml-2 '>
                        <div className='card-body'>
                            <div className='col-md-12'>
                                <h5 className="card-title mb-3">Update subscription #{subscription?.id ?? ""}</h5>

                                <CustomAlertMessage alertType={alertMessage.type}
                                    showAlertMessage={alertMessage.display}
                                    alertMessage={alertMessage.message}
                                />

                                <div className='row'>
                                    <small className='text-mute'>This subscription belongs to <b>{subscription?.user?.email ?? ""}</b> . Update the subscription status , start date and end date.<Link to={`/admin/order/${subscription?.order?.id}`}  title={`Update ${subscription?.order?.order_number}`}> click here to update order {subscription?.order?.order_number} status</Link>
                                    </small>

                                    <form className='form mt-2' onSubmit={handleSubmit}>

                                        <div className='row'>
                                            <div className='form-group col-md-4'>
                                                <label id='order_number'>Order Number</label>
                                                <input className='form-control mt-1' id='order_number' placeholder='Order Number'
                                                    value={subscription?.order?.order_number ?? ""} readOnly />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label id='order_status'>Order Status</label>
                                                <input className='form-control mt-1' id='order_status' value={subscription?.order?.status ?? ""}
                                                    placeholder='Order Status' readOnly />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label id='order_creation_date'>Order Creation date</label>
                                                <input className='form-control mt-1' id='order_creation_date' value={formatDate(subscription?.order?.created_at)}
                                                    placeholder='Order creation date' readOnly />
                                            </div>
                                        </div>

                                        {subscription?.subscription_items?.map(item => {
                                            return <div className='row mt-2' key={item.id}>
                                                <div className='form-group col-md-4'>
                                                    <label id='plan_name'>Plan name</label>
                                                    <input className='form-control mt-1' id='plan_name' value={item?.service_plan?.name ?? ""}
                                                        placeholder='Plan name' readOnly />
                                                </div>

                                                <div className='form-group col-md-4'>
                                                    <label id='plan_price'>Plan price ($)</label>
                                                    <input className='form-control mt-1' id='plan_price' value={item?.service_plan?.price ?? ""}
                                                        placeholder='Plan price' readOnly />
                                                </div>

                                                <div className='form-group col-md-4'>
                                                    <label id='plan_description'>Plan description</label>
                                                    <textarea className='form-control mt-1' id='plan_description' value={item?.service_plan?.description ?? ""}
                                                        placeholder='Plan description' rows={5} readOnly ></textarea>
                                                </div>
                                            </div>

                                        })}
                                        <div className='row mt-2'>
                                            <div className='form-group col-md-4'>
                                                <label id='subscription_status'>Subscription Status <span style={{color:"red"}}>*</span> </label>
                                                <select name="subscription_status" value={formData.subscription_status} 
                                                onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                                    required id="subscription_status" className='form-control mt-1'>
                                                    <option value="">Select status</option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="CANCELLED">CANCELLED</option>
                                                    <option value="SUSPENDED">SUSPENDED</option>
                                                    <option value="PENDING_ADMIN_CONFIRMATION">PENDING_ADMIN_CONFIRMATION</option>
                                                </select>
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label id='subscription_start_date'>Subscription start date <span style={{color:"red"}}>*</span></label>
                                                <input type='date' className='form-control mt-1' value={formData.subscription_start_date} 
                                                onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                                name="subscription_start_date" id='subscription_start_date' required />
                                            </div>
                                            <div className='form-group col-md-4'>
                                                <label id='subscription_end_date'>Subscription end date <span style={{color:"red"}}>*</span></label>
                                                <input type='date' className='form-control mt-1' value={formData.subscription_end_date} 
                                                onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                                name='subscription_end_date' id='subscription_end_date' required />
                                            </div>
                                        </div>

                                        <div className='row mt-4'>

                                            <div className='form-group col-md-4'>
                                                <p>There are <b>{daysInBetween}</b> days between the subscription start date and end date.</p>
                                                <button className='btn btn-success' type='submit'>Update subscription</button> &nbsp;&nbsp;&nbsp;
                                                <Link className='btn btn-outline-secondary' to={'/admin/subscriptions'}>Go back to subscriptions</Link>

                                            </div>
                                        </div>

                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UpdateSubscription;