import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../config/AuthContext';

function Navbar() {
    const {logout} = useAuth()
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-1">
            <Link className="navbar-brand " to="/admin">Diadem Careers</Link>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarContent"
                aria-controls="navbarContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <Link className="nav-link " to="/admin">
                            Home <span className="sr-only">(current)</span>
                        </Link>
                    </li>
                </ul>
                <div className="d-flex ms-auto">
                    <Link to={"/profile"} className="btn btn-sm btn-outline-default">
                        Manage profile
                    </Link> &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-sm btn-outline-info" onClick={logout}>
                        Logout
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
