import React from 'react';
import { Link } from 'react-router-dom';
import FormatDate from './FormatDate';


export const server_base_url = process.env.REACT_APP_SERVER_BASE_URL

function ServiceRow({ service, editService }) {


    return (
        <>
            <tr>
                <td>{service.id}</td>
                <td className='text-left'>
                    {service.title}
                </td>
                <td>{service.image && <><img src={server_base_url + service.image} width={80} className="rounded float-right" alt={service.title} /> <br /></>}</td>
                <td width={500}>{service.description}</td>
                <td>{service.name_id}</td>
                <td><FormatDate dateString={service.created_at} /></td>
                <td><FormatDate dateString={service.updated_at} /></td>
                <td width={200}>
                    {service.name_id === "resume-writing" && <><Link to={`/admin/create-service-questions/${service.id}`} className='btn btn-xs btn-outline-secondary'
                        title={service.title} >Add questions</Link> &nbsp;<Link to={`/admin/view-service-questions/${service.id}`} className='btn btn-xs btn-outline-secondary'
                        title={service.title} >View questions</Link> <br/></>}

                    <Link to={`/admin/service/${service.id}`} className='btn btn-xs btn-outline-info'
                        title={service.title} >View </Link> &nbsp;
                    <button onClick={() => editService(service)} className='btn btn-xs btn-outline-success'>Edit</button>

                </td>
            </tr>
        </>
    );
}

export default ServiceRow;