import React, { useState } from "react";
import FormatDate from "../partials/FormatDate";
import PaginationControls from "../partials/PaginationControls";

function LessonContentForm({ content = [] , handleLessonContentForm, handleContentInputCheckBox}) {
    // Pagination state

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calculate the indices for the data to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = content.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(content.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return <>
        <form className="form" onSubmit={handleLessonContentForm}>
            <h6>Select content for the lesson</h6>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map(item => <tr className={`${item.isChecked ? "table-success" : ""}`} key={item.id}>
                        <td width={70}><b>{item.id}</b> &nbsp;
                            <input type="checkbox" checked={item.isChecked} onChange={(e)=>handleContentInputCheckBox(item)} className="form-check-input" />
                        </td>
                        <td onClick={()=>handleContentInputCheckBox(item)}>
                            <small>{item.title}</small>
                        </td>
                        <td onClick={()=>handleContentInputCheckBox(item)}>
                            <small>{item.name}</small>
                        </td>
                        <td onClick={()=>handleContentInputCheckBox(item)}>
                            <small>{item.description}</small>
                        </td>
                        <td onClick={()=>handleContentInputCheckBox(item)}>
                            <small><FormatDate dateString={item.created_at} /></small>
                        </td>
                        <td onClick={()=>handleContentInputCheckBox(item)}>
                            <small><FormatDate dateString={item.updated_at} /></small>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            <button className="btn btn-outline-success" type="submit">Save module content</button>
        </form>
        {/* Pagination Controls */}
        <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
    </>
}

export default LessonContentForm