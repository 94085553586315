import React,{useState} from 'react';
import PaginationControls from '../partials/PaginationControls';
import FormatDate from '../partials/FormatDate';
import { Link } from 'react-router-dom';
function OrdersTable({orders}){
    // Pagination state

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Calculate the indices for the data to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(orders.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

    return <>
    <table className='table mt-2 table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order Number</th>
                      <th>Status</th>
                      <th>Customer</th>
                      <th>Created by</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map(order => <tr key={order?.id}>
                      <td>{order?.id}</td>
                      <td>{order?.order_number}</td>
                      <td>{order?.status}</td>
                      <td>{order?.user?.email}</td>
                      <td>{order?.created_by?.email}</td>
                      <td><FormatDate dateString={order.created_at} /></td>
                      <td><FormatDate dateString={order.updated_at} /></td>
                      <td>
                      <Link to={`/admin/order/view/${order.id}`} className='btn btn-xs btn-outline-success' title={`View ${order.order_number}`}>View</Link> &nbsp;&nbsp;
                        <Link to={`/admin/order/${order.id}`} className='btn btn-xs btn-outline-info' title={`Update ${order.order_number}`}>Update</Link>
                      </td>
                    </tr>)}

                    {orders.length == 0 && <tr><td colSpan={7} className='text-center'>No records found!</td></tr>}
                  </tbody>
                </table>
                {/* Pagination Controls */}
                <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
    </>
}


export default OrdersTable