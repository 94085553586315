import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import OrderItemsTable from './OrderItemsTable';


function OrderItems() {

  const [alertMessage, setAlertMessage] = useState({
    "type": "info",
    "display": false,
    "message": "",
    "isLoading": false
  })

  const [orderItems, setOrderItems] = useState([])

  
  useEffect(() => {
    fetchOrderItems()
  }, [])



  const fetchOrderItems = async (e) => {
    setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
    try {
      const { data } = await axiosInstance.get(`/admin/order-items/`)
      const {orderItems} = data
      setOrderItems(orderItems)
    } catch (error) {
      setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
    } finally {
      setTimeout(() => {
        setAlertMessage({
          "type": "info",
          "display": false,
          "message": "",
          "isLoading": false
        })

      }, 1000)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <AdminSideBar />
        </div>
        <div className='col-md-10 mt-1'>
          <div className='card mt-2 ml-2 '>
            <div className='card-body'>
              <div className='col-md-12'>
                <h5 className="card-title ">Order Items</h5>
                <CustomAlertMessage alertType={alertMessage.type}
                  showAlertMessage={alertMessage.display}
                  alertMessage={alertMessage.message}
                />

                <OrderItemsTable orderItems={orderItems}/>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default OrderItems;