import React, {  useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar";
import CustomAlertMessage from "../partials/CustomAlertMessage";
import axiosInstance from "../../config/axiosInstance";
import { useParams } from "react-router-dom";

const UpdateResumeQuestionGroup = () => {
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const {groupId} = useParams()


    const [ resumeQuestionGroup , setResumeQuestionGroup] = useState({"name":""})

    useEffect(()=>{
        fetchQuestionGroup()
    },[])


    const fetchQuestionGroup = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data:{data} } = await axiosInstance.get(`/admin/resume-question-groups/${groupId}/`)
            setResumeQuestionGroup(data)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }


    const handleSubmit = async (e)=>{
        e.preventDefault()

        const formData = {name:resumeQuestionGroup.name }
        
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Updating data.... please wait!" })
        try {
            const { data } = await axiosInstance.put(`/admin/resume-question-groups/${groupId}/`,formData)
            setAlertMessage({ "type": "success", display: true, isLoading: true, message: data.message })
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    return (
        <div className="container-fluid">

            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10 mt-1">
                    <div className="card mt-2  mr-2" >

                        <div className="card-body">
                            <h5 className="card-title mb-3">Create new resume question group</h5>
                            <CustomAlertMessage alertType={alertMessage.type}
                                showAlertMessage={alertMessage.display}
                                alertMessage={alertMessage.message}
                            />

                            <div className="row">
                                <form className="form row" onSubmit={handleSubmit}>
                                    <div className="form-group col-md-4">
                                        <label className="label" htmlFor="name">Name</label>
                                        <input className="form-control mt-2" value={resumeQuestionGroup.name} onChange={e=>setResumeQuestionGroup({[e.target.name]:e.target.value})} 
                                        placeholder="Enter question group name" name="name" required id="name"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <button className="btn btn-sm btn-outline-success mt-3">Submit</button> 
                                    </div>
                                </form>
                            </div>

                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default UpdateResumeQuestionGroup;
