import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import { Link } from 'react-router-dom';

function UserGroups() {

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const [userGroups, setUserGroups] = useState([])


    useEffect(() => {
        fetchUserGroups()
    }, [])



    const fetchUserGroups = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data:{results} } = await axiosInstance.get(`/admin/groups/`)
            console.log(results)
            setUserGroups(results)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className='col-md-10 mt-1'>
                    <div className='card mt-2 ml-2 '>
                        <div className='card-body'>
                            <div className='col-md-12'>
                                <h5 className="card-title ">User Groups</h5>
                                <small>The groups organize the users of the system</small>
                                <CustomAlertMessage alertType={alertMessage.type}
                                    showAlertMessage={alertMessage.display}
                                    alertMessage={alertMessage.message}
                                />

                                <div className='row'>

                                    <div className='col-md-12 col-sm-12'>
                                        <table className='table mt-2 table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>#</td>
                                                    <td>Name</td>
                                                    <td>Permissions</td>
                                                    <td>Action</td>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {userGroups.map(group=> <tr key={group.id}>
                                                    <td>{group.id}</td>
                                                    <td>{group.name}</td>
                                                    <td>{group?.permissions?.map(item=><span key={item.id}>{item.name} , </span>)}</td>
                                                    <td>Coming soon</td>
                                                </tr>)}
                                            </tbody>

                                        </table>
                                    </div>

                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UserGroups;