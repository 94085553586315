import React,{useState} from 'react';
import PaginationControls from '../partials/PaginationControls';
import FormatDate from '../partials/FormatDate';
function SubscriptionItemsTable({subscriptionItems}){
    // Pagination state

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the indices for the data to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subscriptionItems.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(subscriptionItems.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

    return <>
    <table className='table mt-2 table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order Number</th>
                      <th>Subscription Status</th>
                      <th>Customer</th>
                      <th>Plan Name</th>
                      <th>Subscription Start Date</th>
                      <th>Subscription End Date</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map(subscriptionItem => <tr key={subscriptionItem.id}>
                      <td>{subscriptionItem.id}</td>
                      <td>{subscriptionItem?.subscription?.order?.order_number}</td>
                      <td>{subscriptionItem?.subscription?.status}</td>
                      <td>{subscriptionItem?.subscription?.order?.user.email}</td>
                      <td>{subscriptionItem?.service_plan?.name}</td>
                      <td><FormatDate dateString={subscriptionItem?.subscription?.start_date} /></td>
                      <td><FormatDate dateString={subscriptionItem?.subscription?.end_date} /></td>
                      <td><FormatDate dateString={subscriptionItem.created_at} /></td>
                      <td><FormatDate dateString={subscriptionItem.updated_at} /></td>
                    </tr>)}
                    {currentItems.length==0 && <tr key={1}><td className='text-center' colSpan={9}>No data available at the moment</td></tr>}
                  </tbody>
                </table>
                {/* Pagination Controls */}
                <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
    </>
}


export default SubscriptionItemsTable