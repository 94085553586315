function QuestionsFormTable({ questions , handleQuestionInputChangeEvent}) {
    return <table className="mt-2">
        <tbody>
            {questions.map(item => <tr key={item.id}>
                <td>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value={item.isChecked} onChange={(e) => handleQuestionInputChangeEvent(e,item)} id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            {item.question}
                        </label>
                    </div>
                </td>
            </tr>)}
        </tbody>
    </table>
}


export default QuestionsFormTable