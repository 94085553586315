import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance";
const AuthContext = createContext(null); // Ensure it's initialized

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  
    useEffect(() => {
      // Update token from localStorage in case of a page refresh
      setAuthToken(localStorage.getItem("authToken"));
    }, []);

    const login = (token) => {
        localStorage.setItem("authToken", token);
        setAuthToken(token);
    };

    const logout = async() => {
        try {
            const formData = { "refresh": localStorage.getItem("refresh") };
            const response = await axiosInstance.post("/token/deactivate/", formData);
        } catch (error) {
        }

        localStorage.clear();
        setAuthToken(null);
        navigate("/login")
    };

    return (
        <AuthContext.Provider value={{ authToken, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
