import React, { useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar";
import { Link, useParams } from "react-router-dom";
import ServiceOfferingCard from "../Services/ServiceOfferingCard";
import ModuleForm from "./ModuleForm";
import CreateModuleLessonFile from "./CreateModuleLessonFile";
import axiosInstance from "../../config/axiosInstance";
import Module from "./Module";


function CreateModuleLesson() {
    const { serviceId, serviceOfferingId } = useParams()
    const [service, setService] = useState(null)
    const [serviceOfferings, setServiceOfferings] = useState([])
    const [lessons, setModules] = useState([])
    const [serviceOffering, setCurrentServiceOffering] = useState(null)
    const [isFormSendingData, setIsFormSendingData] = useState(false)
    const [responseMessage, setResponseMessage] = useState("")


    useEffect(() => {
        fetchCourseData()
    }, [])

    const [moduleFormData, setModuleFormData] = useState({
        "description": "",
        "name": ""
    })


    const fetchCourseData = async () => {

        try {
            const responseData = await axiosInstance.get(`/services/${serviceId}`)
            const {service, } = responseData.data
            setService(service)
            setServiceOfferings(service.service_offerings)
            const currentServiceOffering = service.service_offerings.find(item=>item.id==serviceOfferingId)
            setCurrentServiceOffering(currentServiceOffering)
            setModules(currentServiceOffering.lessons)
        } catch (err) {
        } finally {
        }


    }

    const handleInputOnChange = (event) => {
        const { name, value } = event.target;
        setModuleFormData({
            ...moduleFormData,
            [name]: value,
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if(isEdditingLesson){
            updateLesson()
            return
        }
        const formData = { ...moduleFormData, "course_topic": serviceOfferingId }
        setResponseMessage("Sending data, please wait....")
        setIsFormSendingData(true)

        try {
            const response = await axiosInstance.post(`/topic-lessons/`, formData)
            const responseData = response.data
            const status = response.status
            const { message, data } = responseData
            setResponseMessage(message)
            if(status==201){
                setModuleFormData({
                    "description": "",
                    "name": ""
                })
                setModules((previousItems) => [data, ...previousItems])
            }
        } catch ({message}) {
            setResponseMessage(`Error ocurred! ${message}`)
        } finally {
            setTimeout(()=>{
                setResponseMessage("")
                setIsFormSendingData(false)
            },1000)
        }
    }

    const [userAlertMessage, setUserAlertMessage] = useState(null)
    const deleteServiceOfferingModule = async (lesson) => {
        if (window.confirm(`Delete lesson , NAME: ${lesson.name} , DESCRIPTION: ${lesson.description}`)) {
            const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
            alertDiv.classList.remove('d-none');
            setUserAlertMessage(`Deleting lesson , please wait.....`)

            const deleteButton = document.getElementById(`delete-lesson-button-${lesson.id}`)
            deleteButton.classList.add('d-none');
            try {
                const {status} = await axiosInstance.delete(`/topic-lessons/${lesson.id}/`)
                if(status==204){
                    setModules(prev=>prev.filter(item=>item.id!==lesson.id))
                    setUserAlertMessage(`Lesson has been deleted successfully!`)
                }

            } catch (err) {
            } finally {
                setTimeout(()=>{
                    setIsFormSendingData(false)
                    setUserAlertMessage("")
                },3000)
            }
        }

    }

    const [isEdditingLesson, setIsEdittingLesson] = useState(false)
    const [lesson, setLesson] = useState({})

    const editServiceOfferingModule = async (moduleParam) => {
        resetCRUDViewOperation()
        const alertDiv = document.getElementById(`user-alert-message-${moduleParam.id}`);
        alertDiv.classList.remove('d-none');
        setUserAlertMessage(`You are currently editting this lesson`)

        hideEditDeleteAddFileButtons(moduleParam)
        setLesson(moduleParam)
        setModuleFormData(moduleParam)
        setIsEdittingLesson(true)
    }

    const hideEditDeleteAddFileButtons = (moduleParam)=>{
        const deleteButton = document.getElementById(`delete-lesson-button-${moduleParam.id}`)
        deleteButton.classList.add('d-none');
        const editButton = document.getElementById(`edit-lesson-button-${moduleParam.id}`)
        editButton.classList.add('d-none');
        const addFileButton = document.getElementById(`add-file-button-${moduleParam.id}`)
        addFileButton.classList.add('d-none');
    }

    const showEditDeleteAddFileButtons = (moduleParam)=>{
        setIsAddingServiceOfferingModuleFile(false)
        const deleteButton = document.getElementById(`delete-lesson-button-${moduleParam.id}`)
        deleteButton.classList.remove('d-none');
        const editButton = document.getElementById(`edit-lesson-button-${moduleParam.id}`)
        editButton.classList.remove('d-none');
        const addFileButton = document.getElementById(`add-file-button-${moduleParam.id}`)
        addFileButton.classList.remove('d-none');

    }


    const updateLesson = async ()=>{
        setResponseMessage("Sending data, please wait....")
        setIsFormSendingData(true)
        const formData = { ...moduleFormData, "course_topic": serviceOfferingId }
        try {
            const response = await axiosInstance.put(`/topic-lessons/${lesson.id}/`, formData)
            const responseData = response.data
            const status = response.status
            const { message, data } = responseData
            setResponseMessage(message)
            if(status==200){
                setModules(prev=>{
                    return prev.map(item=>{
                        if(item.id == data.id) return data;
                        return item;
                    })
                })

                setTimeout(()=>cancelLessonEdit(), 1000)
            }
        } catch (err) {
            setResponseMessage(`Error ocurred , try again later! `)
        } finally {
            setTimeout(()=>{
                setIsFormSendingData(false)
            },1000)
        }
    }

    const cancelLessonEdit = () => {
        const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
        alertDiv.classList.add('d-none');

        showEditDeleteAddFileButtons(lesson)
        setIsEdittingLesson(false)
        setUserAlertMessage("")
        setLesson({})

        setResponseMessage("")
        setModuleFormData({
            "description": "",
            "name": ""
        })

        setIsFormSendingData(false)
        setIsAddingServiceOfferingModuleFile(false)

    }


    const [isAddingServiceOfferingModuleFile, setIsAddingServiceOfferingModuleFile] = useState(false)

    const resetCRUDViewOperation = ()=> {
        if(lesson.id){
            setIsAddingServiceOfferingModuleFile(false)
            const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
            alertDiv.classList.add('d-none');
            showEditDeleteAddFileButtons(lesson)
        }
    }

    const addServiceOfferingModuleFile = (moduleParam) =>{
        resetCRUDViewOperation()
        setLesson(()=>moduleParam)
        setIsAddingServiceOfferingModuleFile(true)

        hideEditDeleteAddFileButtons(moduleParam)

        const alertDiv = document.getElementById(`user-alert-message-${moduleParam.id}`);
        alertDiv.classList.remove('d-none');
        setUserAlertMessage(`You are currently adding a file to this lesson`)
    } 

    const CancelServiceOfferingLessonFileAddition = ()=>{
        if(!window.confirm("Cancel lesson file addition operation?")) return
        showEditDeleteAddFileButtons(lesson)
        setUserAlertMessage("")
        const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
        alertDiv.classList.add('d-none');
        setLesson({})

    }
    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <h4>Manage service offering module</h4>

                    <Link to={`/admin/service/${serviceId}`} style={{ position: "absolute", right: "40px", top: "63px" }}
                    className="btn btn-sm btn-outline-secondary ">Go back to Service</Link>

                    <div className="row mb-4">
                        <div className="col-md-3">
                            {service ? <ServiceOfferingCard service={service} /> : <div className="alert alert-info mt-2">Service information not found</div>}
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-body">
                                    <div className="alert alert-secondary">
                                        <small className="text-success font-weight-bold">Service offering description</small> &nbsp;
                                        <small>
                                            {serviceOffering?.plan_value_proposition?.description}
                                        </small>
                                    </div>
                                    
                                    <ModuleForm
                                        setIsAddingServiceOfferingModuleFile={setIsAddingServiceOfferingModuleFile}
                                        isAddingServiceOfferingLessonFile={isAddingServiceOfferingModuleFile}
                                        lesson={lesson}
                                        moduleFormData={moduleFormData}
                                        responseMessage={responseMessage}
                                        handleInputOnChange={handleInputOnChange}
                                        isFormSendingData={isFormSendingData}
                                        isEdditingLesson={isEdditingLesson}
                                        handleSubmit={handleSubmit}
                                        cancelLessonEdit={cancelLessonEdit}
                                    />

                                    <CreateModuleLessonFile
                                        isAddingServiceOfferingLessonFile={isAddingServiceOfferingModuleFile}
                                        lesson={lesson}
                                        setModules={setModules}
                                        CancelServiceOfferingLessonFileAddition={CancelServiceOfferingLessonFileAddition}
                                    />
                                </div>
                            </div>

                            <div className="card mt-3">

                                <div className="card-body">
                                    <div className="card-title">
                                        {
                                            lessons.length > 0 ?
                                                <>
                                                    <h5 className="mb-3 text-info">Service offerings modules</h5>

                                                    <div className="list-group">
                                                        {
                                                            lessons.map(item =>
                                                                <Module key={item.id} module={item} 
                                                                    deleteServiceOfferingModule={deleteServiceOfferingModule}
                                                                    serviceOfferingId={serviceOfferingId}
                                                                    courseId={serviceId}
                                                                    editServiceOfferingModule={editServiceOfferingModule}
                                                                    addServiceOfferingModuleFile={addServiceOfferingModuleFile}
                                                                    isAddingServiceOfferingModuleFile={isAddingServiceOfferingModuleFile}
                                                                    setIsAddingServiceOfferingModuleFile={setIsAddingServiceOfferingModuleFile}
                                                                    userAlertMessage={userAlertMessage} />
                                                            )
                                                        }
                                                    </div>

                                                </> :
                                                <div className="alert alert-info">This service offering has no module available</div>
                                        }



                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CreateModuleLesson;