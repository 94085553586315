import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import CustomAlertMessage from "../partials/CustomAlertMessage"
import { Link, useParams } from 'react-router-dom';
import FormatDate from '../partials/FormatDate';


function UpdateOrder() {

    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })

    const { orderId } = useParams()

    const [order, setOrder] = useState({})

    const [formData , setFormData] = useState({
        "status":"",
        "recorded_cost":0
    })
    useEffect(() => {
        fetchOrder()
        
    }, [])



    const fetchOrder = async (e) => {
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data } = await axiosInstance.get(`/admin/orders/${orderId}/`)
            const {order} = data
            if(order.status){
                setFormData(prev=>({...prev,status:order.status}))
            }
            if(order.recorded_cost){
                setFormData(prev=>({...prev,recorded_cost:order.recorded_cost}))
            }
            setOrder(order)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()
        const form_data = {...formData, orderId}
        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "submitting data.... please wait!" })
        try {
            const { data } = await axiosInstance.post(`/admin/orders/`,form_data)
            const {message} = data
            setAlertMessage(prev => ({ ...prev, type: "success", message }))
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className='col-md-10 mt-1'>
                    <div className='card mt-2 ml-2 '>
                        <div className='card-body'>
                            <div className='col-md-12'>
                                <h5 className="card-title ">Update order #{order?.id}</h5>
                                <small> Order number <b>{order?.order_number}</b>. Belongs to <b>{order?.user?.email} </b> and was created on  <b><FormatDate dateString={order?.created_at} /></b></small>
                                <CustomAlertMessage alertType={alertMessage.type}
                                    showAlertMessage={alertMessage.display}
                                    alertMessage={alertMessage.message}
                                />
                                <hr/>

                                <form className='form row' onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='form-group col-md-4'>
                                            <label htmlFor='order_status' className='label mt-1'>Order status</label>
                                            <select name="status" id="order_status"  value={formData.status} onChange={(e)=>setFormData((prev)=>({...prev, [e.target.name]:e.target.value}))}
                                            required className='mt-1 form-control' >
                                                <option value="">select status</option>
                                                <option value="INPROGRESS">INPROGRESS</option>
                                                <option value="COMPLETED">COMPLETED</option>
                                                <option value="CANCELLED">CANCELLED</option>
                                                <option value="PENDING_PAYMENT" >PENDING_PAYMENT</option>

                                            </select>
                                        </div>
                                        {/* <div className='form-group col-md-4'>
                                            <label htmlFor='recorded_cost' className='label mt-1'>Recorded cost</label>
                                            <input type='number' min={0} readOnly value={formData.recorded_cost} onChange={(e)=>setFormData((prev)=>({...prev, [e.target.name]:e.target.value}))}
                                            name='recorded_cost' id='recorded_cost' className='form-control mt-1'/>
                                        </div> */}
                                    </div>
                                    <div className='row mt-4'>

                                            <div className='form-group col-md-4'>
                                                <button className='btn btn-success' type='submit'>Update order</button> &nbsp;&nbsp;&nbsp;
                                                <Link className='btn btn-outline-secondary' to={'/admin/orders'}>Go back to orders</Link>

                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UpdateOrder;