import React from "react";

import { useAuth } from "./config/AuthContext";
import AppRoutes from "./config/AppRoutes";
import Navbar from "./admin/partials/Navbar";
import Footer from "./admin/partials/Footer";
function App() {
  const { authToken } = useAuth();
  return (
    <div className="container-fluid" style={{marginBottom:"100px"}}>
        {authToken && <Navbar />}
        <AppRoutes />
        <Footer/>
    </div>
  );
}

export default App;