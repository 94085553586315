import React from 'react';

import { server_base_url } from "../partials/ServiceRow";
import FormatDate from "../partials/FormatDate";

function ServiceOfferingCard({service}) {
    return (
        <div className="card" >
            <img className="card-img-top mt-1" height={330}  src={server_base_url + service?.image} alt={service?.title} />
            <div className="card-body">
                <h5 className="card-title">{service?.title}</h5>
                <p className="card-text">{service?.description}</p>
                <small><b>Created On:</b> <FormatDate dateString={service?.created_at} /></small> <br />
                <small><b>Last updated:</b> <FormatDate dateString={service?.updated_at} /></small>

                <hr/>
                <p className='text-success'>
                    This service has <b>{service?.service_offerings?.length}</b> offerings.
                </p>
            </div>
        </div>

    );
}

export default ServiceOfferingCard;