import React, { useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar";
import axiosInstance from "../../config/axiosInstance";
import ContentTable from "./ContentTable";
import ContentForm from "./ContentForm";
import ContentCard from "./ContentCard";
import CustomAlertMessage from "../partials/CustomAlertMessage"

export function stripHtmlTags(htmlString) {
    // Use a regex to replace all HTML tags with an empty string
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "").trim();
}

function ContentIndex() {
    const [formData, setFormData] = useState({
        "name": "",
        "title": "",
        "description": ""
    })
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })
    const [isEdittingContent, setIsEdittingContent] = useState(false)
    const [content, setContent] = useState([])
    const [displayFormData, setDisplayFormData] = useState(false)
    const [displayContent, setDisplayContent] = useState(false)
    const [currentViewContent, setCurrentViewContent] = useState({})
    useEffect(() => {
        fetchData()
    }, [])

    
    const fetchData = async (e) => {
        setAlertMessage(prev => ({ ...prev, display: true, isLoading: true, message: "Fetching data.... please wait!" }))
        try {
            const { data } = await axiosInstance.get(`/content/`)
            const formattedContent = data.content.map(item => ({
                ...item,
                name: stripHtmlTags(item.name),
                description: stripHtmlTags(item.description),
                title: stripHtmlTags(item.title),
            }))
            setContent(formattedContent)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })
                setIsEdittingContent(false)
            }, 1000)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formData.description.trim() === "") {
            setAlertMessage(prev => ({ ...prev, type: "danger", display: true, isLoading: false, message: "Content description is required!" }))
            setTimeout(() => resetAlertMessageValues(), 1000)
            return
        }
        setAlertMessage(prev => ({ ...prev, type: "info", display: true, isLoading: true, message: "Sending data.... please wait!" }))
        try {
            if (isEdittingContent) {

                const { data } = await axiosInstance.put(`/content/${formData.id}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                const { message, content } = data
                setContent(prev => prev.map(item => (item.id === content.id ? {
                    ...content,
                    name: stripHtmlTags(content.name),
                    description: stripHtmlTags(content.description),
                    title: stripHtmlTags(content.title),
                } : item)))
                setAlertMessage(prev => ({ ...prev, type: "success", message }))
                setIsEdittingContent(false)

            } else {
                const { data } = await axiosInstance.post(`/content/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                const { message, content } = data
                const formattedContent = {
                    ...content,
                    name: stripHtmlTags(content.name),
                    description: stripHtmlTags(content.description),
                    title: stripHtmlTags(content.title),
                }
                setContent(prev => [formattedContent, ...prev])
                setAlertMessage(prev => ({ ...prev, type: "success", message }))
            }


            setFormData({
                "name": "",
                "title": "",
                "description": ""
            })
            setDisplayFormData(false)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })
            }, 2000)

        }
    }

    const editContent = async (contentId) => {
        setAlertMessage(prev => ({ ...prev, display: true, type: "info", message: "Fetching content information.. please wait!" }))
        try {
            const response = await axiosInstance.get(`/content/${contentId}`)
            const content = response.data
            const data = { ...content, files: [], urls: [] }
            setIsEdittingContent(true)
            setFormData(data)
            setDisplayFormData(true)
            setDisplayContent(false)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                resetAlertMessageValues()
            }, 1000)
        }
    }

    const deleteContent = async (content) => {
        setFormData({
            "name": "",
            "title": "",
            "description": ""
        })
        if (!window.confirm(`Delete : ${content.description}`)) return

        setAlertMessage(prev => ({ ...prev, display: true, isLoading: true, message: `Deleting content "${content.description}".... please wait!` }))
        try {
            await axiosInstance.delete(`/content/${content.id}/`)
            setContent(prev => prev.filter(item => item.id !== content.id))
            setAlertMessage(prev => ({ ...prev, type: "success", message: "Content deleted successfully!" }))
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                resetAlertMessageValues()
                setIsEdittingContent(false)
                setDisplayContent(false);
                setDisplayFormData(false)
                setCurrentViewContent({})
            }, 1000)
        }
    }



    const viewContent = async (contentId) => {
        setAlertMessage(prev => ({ ...prev, display: true, type: "info", message: "Fetching content information.. please wait!" }))
        try {
            const response = await axiosInstance.get(`/content/${contentId}`)
            const content = response.data
            setDisplayContent(true)
            setIsEdittingContent(false)
            setDisplayFormData(false)
            setCurrentViewContent(content)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                resetAlertMessageValues()
                setIsEdittingContent(false)
            }, 1000)
        }
    }


    const deleteContentUrl = async (contentId, item) => {
        if (!contentId) {
            return setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        }
        if (!window.confirm(`Delete: ${item.url}`)) return
        const url = `/content/${contentId}/delete-content-url/${item.id}/`

        setAlertMessage(prev => ({ ...prev, display: true, isLoading: true, message: `Deleting content url "${item?.url}".... please wait!` }))

        try {
            await axiosInstance.delete(url)
            const urls = currentViewContent.urls.filter(url => url.id !== item.id)
            setCurrentViewContent(prev => ({ ...prev, urls }))
            setAlertMessage(prev => ({ ...prev, type: "success", message: "Content URL deleted successfully!" }))
        } catch (error) {
            return setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                resetAlertMessageValues()
                setIsEdittingContent(false)
            }, 1000)
        }
    }

    const resetAlertMessageValues = () => {
        setAlertMessage({
            "type": "info",
            "display": false,
            "message": "",
            "isLoading": false
        })
    }

    const deleteContentFile = async (contentId, item) => {

        if (!contentId) {
            return setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        }
        const url = `/content/${contentId}/delete-content-file/${item.id}/`
        if (!window.confirm(`Delete: ${item?.file?.name}`)) return

        setAlertMessage(prev => ({ ...prev, display: true, isLoading: true, message: `Deleting content file "${item?.file?.name}".... please wait!` }))

        try {
            await axiosInstance.delete(url)
            const files = currentViewContent.files.filter(file => file.id !== item.id)
            setCurrentViewContent(prev => ({ ...prev, files }))
            setAlertMessage(prev => ({ ...prev, type: "success", message: "Content File deleted successfully!" }))
        } catch (error) {
            return setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })
                setIsEdittingContent(false)
            }, 1000)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <h4>Content</h4>
                    <small className="text-success">Content is part of the lessons of a topic. A lesson can have many content items. <b>Refer to the specific topic lesson as a guide on what should be in the content.</b> </small>
                    <button style={{ position: "absolute", right: "40px", top: "63px" }} type="button"
                        onClick={() => setDisplayFormData(prev => !prev)}
                        className={`btn btn-sm ${displayContent ? "d-none" : ""} 
                        btn-outline-${!displayFormData ? "secondary" : "primary"}`}>
                        {!displayFormData ? "Create content" : "Show defined content"}</button>

                    <button style={{ position: "absolute", right: "40px", top: "63px" }} type="button"
                        onClick={() => { setDisplayContent(false); setDisplayFormData(false) }}
                        className={`btn btn-sm ${!displayContent ? "d-none" : ""} 
                        btn-outline-info`}>View contents</button>
                    <div className="row">
                        <div className={`col-md-11 alert alert-${alertMessage.type} ${!alertMessage.display ? "d-none" : ""}`}>
                            {alertMessage.message}
                        </div>
                    </div>
                    <div className={`row mt-2 ${!displayContent ? "d-none" : ""}`}>
                        <div className="col-md-12">
                            <ContentCard content={currentViewContent} deleteContentFile={deleteContentFile}
                                editContent={editContent}
                                deleteContent={deleteContent}
                                deleteContentUrl={deleteContentUrl} />
                        </div>
                    </div>

                    <div className={`row mt-2 ${!displayFormData ? "d-none" : ""}`}>
                        <ContentForm
                            handleSubmit={handleSubmit}
                            setFormData={setFormData}
                            formData={formData}
                            isEdittingContent={isEdittingContent} />
                        <CustomAlertMessage alertType={alert.type}
                            showAlertMessage={alert.display}
                            alertMessage={alert.message}
                        />
                    </div>
                    <div className={`row mt-2 ${displayFormData || displayContent ? "d-none" : ""}`}>
                        <ContentTable viewContent={viewContent} content={content} editContent={editContent} deleteContent={deleteContent} />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ContentIndex;