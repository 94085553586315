import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../config/AuthContext';



function AdminSideBar() {
    const navbarList = [

        {
            name: "Dashboard",
            url: "/admin"
        },
        {
            name: "Groups",
            url: "/admin/groups"
        },
        {
            name: "Users",
            url: "/admin/users"
        },
        {
            name: "Services",
            url: "/admin/services"
        },

        {
            name: "Create Services",
            url: "/admin/create-service"
        },
        {
            name: "Service Plans",
            url: "/admin/service-plan"
        },
        {
            name: "Create Service Plan",
            url: "/admin/service-plan/create"
        },
        {
            name: "Orders",
            url: "/admin/orders"
        },
        {
            name: "Resume writing service : Orders",
            url: "/admin/resume-writing-orders"
        },
        {
            name: "Subscriptions",
            url: "/admin/subscriptions"
        },
        {
            name: "Subscriptions Items",
            url: "/admin/subscription-items"
        },
        {
            name: "Plan Value Proposition",
            url: "/admin/plan-value-proposition"
        },
        {
            name: "Content",
            url: "/admin/content"
        },
        {
            name: "Resume Questions",
            url: "/admin/questions"
        },
        {
            name: "Resume Question Groups Names",
            url: "/admin/resume-question-group-names"
        },
        {
            name: "Group resume questions",
            url: "/admin/match-resume-questions-to-group"
        },
    ]
    
    
    const location = useLocation();
    const {logout} = useAuth()
    return (
        <div className="container" style={{ padding: "20px" }}>
            <h6> Admin Portal </h6>
            <ul style={{ marginTop: "20px", marginBottom: "20px" }} className="list-group">
                {
                    navbarList.map((item, index) => (
                        <Link to={item.url} 
                            className={`list-group-item ${location.pathname === item.url ? "list-group-item-info" : ""}`}
                            key={index}>{item.name}</Link>
                    ))
                }
                <a className='list-group-item' target='_blank' href='https://customer.diademcareers.com/'>Go to Customer Portal</a>
            </ul>
            <button className='btn btn-sm btn-outline-info' type='button' onClick={logout}>Log out</button>
        </div>
    );
}
export default AdminSideBar;