import React, { useEffect, useRef, useState } from 'react'
import AdminSideBar from '../AdminSideBar';
import ServiceForm from '../partials/ServiceForm';
import axiosInstance from '../../config/axiosInstance';
import TopicForm from '../partials/TopicForm';


export function getImagePath(url) {
    const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    if (url.startsWith(baseUrl)) {
        return url.replace(baseUrl, '');
    }
    return url;
}



function CreateServiceOffering() {
    const [isEditting, setIsEditting] = useState(false)
    const [services, setServices] = useState([])
    const [planValuePropositions, setPlanValuePropositions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isFormSendingData, setIsFormSendingData] = useState(false)
    const [serviceId, setServiceId] = useState(null)
    const [selectedServiceName, setSelectedServiceName] = useState("")
    const [selectedPlans, setSelectedPlans] = useState([])
    const [formAlertMessage, setFormAlertMessage] = useState("")
    const [alertType, setAlertType] = useState("info")
    const fileInputRef = useRef(null);
    const [topicResponseMessage, setTopicResponseMessage] = useState("")
    const [isSendingTopicData, setIsSendingTopicData] = useState(false)
    const [serviceFormData, setServiceFormData] = useState({
        "title": "",
        "id": "",
        "description": "",
        "service_offerings": []
    })

    const [topicFormData, setTopicFormData] = useState({
        "company_service": "",
        "plan_value_proposition": [],
    })

    const handlePlanCheckBox = (plan) => {
        const findSelectedPlan = selectedPlans.find(p => p.id == plan.id)
        if (!findSelectedPlan) {
            setSelectedPlans(previousArray => ([...previousArray, plan]))
        } else {
            setSelectedPlans(previousArray => ([...previousArray.filter(p => p.id !== plan.id)]))
        }
        setPlanValuePropositions(prev => prev.map(item => {
            if (item.id == plan.id) {
                const prev_plan_value_proposition = topicFormData.plan_value_proposition
                const prev = prev_plan_value_proposition.find(prev_id => prev_id == plan.id)

                if (!prev) {
                    const new_plan_value_proposition = [...prev_plan_value_proposition, plan.id]
                    setTopicFormData(prev => ({ ...prev, plan_value_proposition: new_plan_value_proposition }))
                } else {
                    const new_plan_value_proposition = topicFormData.plan_value_proposition.filter(item => item !== plan.id)
                    setTopicFormData(prev => ({ ...prev, plan_value_proposition: new_plan_value_proposition }))
                }
                if(item.isChecked){
                    return { ...item, isChecked: false }
                }else{
                    return { ...item, isChecked: true }
                }

            }
            return item

        }))

    }



    useEffect(() => {
        fetchServicesData()
    }, [])

    const fetchServicesData = async () => {
        setIsSendingTopicData(true)
        setTopicResponseMessage("Fetching services and offerings, please wait.....")
        try {
            const response = await axiosInstance.get('/services/services-and-offerings/')

            if (response.status == 200) {
                const responseData = response.data
                const { services, plan_value_proposition } = responseData.data
                setServices(services)
                const pvp = plan_value_proposition.map(item => ({ ...item, isChecked: false }))
                setPlanValuePropositions(pvp)
            }
        } catch (error) {
            setIsSendingTopicData(true)
            setTopicResponseMessage("Error ocurred , contact administrator.")
            setAlertType("danger")
        } finally {
            setIsSendingTopicData(false)
            setTopicResponseMessage("")
            setAlertType("info")
        }
    }


    const handleServiceFormDataChange = (event) => {
        const { name, value } = event.target;
        setServiceFormData({
            ...serviceFormData,
            [name]: value,
        });
    };

    const handleTopicFormDataChange = (event) => {
        const { name, value } = event.target;
        const selectedService = services.find(service => service.id == value)
        if (selectedService) {
            setSelectedServiceName(selectedService.title)
        }
        setTopicFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormDataSubmission = async (event) => {
        event.preventDefault();
        setIsFormSendingData(true)
        setFormAlertMessage("Submitting data , please wait")
        const file = fileInputRef.current.files[0];
        const formData = new FormData();
        if (file) {
            formData.append("image", file);
        } else {
        }
        formData.append("title", serviceFormData.title)
        formData.append("description", serviceFormData.description)
        saveServiceInDB(formData)
    }




    const saveServiceInDB = async (formData) => {
        try {
            const response = await axiosInstance.post(`/services/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            const responseData = response.data
            const { message, data } = responseData
            setFormAlertMessage(message)
            setAlertType("success")
            const service = { ...data, "image": getImagePath(data.image) }
            setServices(prev => ([service, ...prev]))
            setTimeout(() => resetServiceForm(), 2000)

        } catch (error) {
            const { message } = error
            setFormAlertMessage(message)
            setAlertType("danger")
        } finally {
            setTimeout(() => {
                setAlertType('info')
                setIsFormSendingData(false)
            }, 2000)
        }

    }

    const resetServiceForm = () => {
        setTimeout(() => {
            setIsFormSendingData(false)
            setFormAlertMessage("")
            setServiceId(null)
        }, 1000)
        setAlertType('info')
        setServiceFormData({ "title": "", "description": "" })
        fileInputRef.current.value = "";
    }



    const handleTopicForm = async (e) => {
        e.preventDefault()
        setIsSendingTopicData(true)
        setTopicResponseMessage("Submitting information , please wait.....")
        try {

            const { data } = await axiosInstance.post(`/service-offerings/`, topicFormData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            setServices(prev => {
                return prev.map(service => {
                    if (service.id == data.company_service) {
                        const service_offerings = service.service_offerings
                        return { ...service, service_offerings: [...service_offerings, data] }
                    }
                    return service
                })
            })

            setAlertType("success")
            setTopicResponseMessage("Service offering created successfully!")
            setPlanValuePropositions(prev => prev.map(item => ({ ...item, isChecked: false })))
            setTimeout(() => {
                
                setTopicResponseMessage("")
                setAlertType("info")
                setTopicFormData({ "serviceId": "", "description": "" })
                setSelectedServiceName("")
                setSelectedPlans([])
            }, 2000)
        } catch (error) {
            setTopicResponseMessage("Error ocurred try again later!")
        } finally {
            setTimeout(()=>{
                setIsSendingTopicData(false)
            },2000)
        }
    }


    const [model, setModel] = useState("Example Set");

    const handleModelChange = (event) => {
        setModel(event)
    }




    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className='col-md-10'>
                    <div className='row'>
                        <div className="col-md-12">

                            <div className='card'>
                                <div className="card-body">
                                    <h5 className="card-title mt-1 mb-1 text-success">{isEditting ? `Editting service ID: ${serviceFormData.id} , TITLE: ${serviceFormData.title} ` : "Create new service"}</h5>

                                    <ServiceForm
                                        alertType={alertType}
                                        serviceFormData={serviceFormData}
                                        fileInputRef={fileInputRef}
                                        formAlertMessage={formAlertMessage}
                                        isEditting={isEditting}
                                        isFormSendingData={isFormSendingData}
                                        handleFormDataSubmission={handleFormDataSubmission}
                                        handleServiceFormDataChange={handleServiceFormDataChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <TopicForm
                            planValuePropositions={planValuePropositions}
                            handleTopicForm={handleTopicForm}
                            handlePlanCheckBox={handlePlanCheckBox}
                            topicFormData={topicFormData}
                            handleTopicFormDataChange={handleTopicFormDataChange}
                            topicResponseMessage={topicResponseMessage}
                            alertType={alertType}
                            services={services}
                            isSendingTopicData={isSendingTopicData}
                            selectedPlans={selectedPlans}
                            selectedServiceName={selectedServiceName}
                        />


                    </div>
                </div>

            </div>
        </div>

    );
}

export default CreateServiceOffering