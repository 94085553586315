import React, { useState } from 'react';
import ServiceRow from './ServiceRow';
import PaginationControls from './PaginationControls';
function ServicesTable({ services, editService }) {
    // Pagination state

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Calculate the indices for the data to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = services.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(services.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return <>
        <small> <b>{services.length}</b> services created.  </small>
        <table className='table table-striped table-bordered'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Title</th>
                    <th>Image</th>
                    <th>Description</th>
                    <th>Name ID <small className='text-danger'>(Don't Edit)</small></th>
                    <th>Created</th>
                    <th>Last Updated</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {currentItems.length > 0 ? currentItems.map((item) => <ServiceRow editService={editService} key={item.id} service={item} />) :
                    <tr>
                        <td className='text-center' colSpan={6}>No data defined!</td>
                    </tr>

                }
            </tbody>
        </table>
        {/* Pagination Controls */}
        <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
    </>
}
export default ServicesTable;