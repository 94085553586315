import React from "react";
import FormatDate from "../partials/FormatDate";
import { server_base_url } from "../partials/ServiceRow";

function ContentCard({ content ,deleteContentFile, deleteContentUrl , editContent, deleteContent }) {
    function getFileExtension(filename) {
        const dotIndex = filename.lastIndexOf('.');
        if (dotIndex === -1) return ''; // No extension found
        return filename.substring(dotIndex + 1);
    }
    
    const displayFile = (item) =>{
        const {name, url , id} = item.file
        const ext = getFileExtension(name)

        const imagesExts = ["jpg", "png" , "jpeg","webp"]

        const isImage = imagesExts.includes(ext);
        const file_url = `${server_base_url}${url}`
        if(isImage){
            return <img src={file_url} title={`#IMG_${id}`} style={{maxWidth:"100%"}} className="img img-responsive m-2" height={250} />
        }

        if(ext == "pdf"){
            // include logic to render pdf
        }
        return file_url
    }
    return <div className="card">
        <div className="card-body row">
            <div className="col-md-6">
                <h6><b>#{content.id}</b> Content details</h6>
                {/* <hr/> */}
                
                {/* <div className="row">
                    <h6 className="text-info">Content title</h6>
                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: content.title }}></div>
                </div>
                <hr/>
                <div className="row">
                    <h6 className="text-info">The content name</h6>
                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: content.name }}></div>
                </div>
                <hr/> */}

                <div className="row">
                    {/* <h6 className="text-info">Content description</h6> */}
                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                </div>
                
                {/* <hr/> */}
                <p><b>Created on</b> <FormatDate dateString={content.created_at} /></p>
                <p><b>Updated on</b> <FormatDate dateString={content.updated_at} /></p>
                {/* <hr/> */}
                <button title={`Edit : ${content.description}`} className="btn btn-sm btn-outline-info" 
                type="button" onClick={()=>editContent(content.id)}>Edit</button> &nbsp;
                <button title={`Delete : ${content.description} !`} className="btn btn-sm btn-outline-danger" 
                type="button" onClick={()=>deleteContent(content)}>Delete</button>
            </div>
            <div className="col-md-6">
                <h6>Files</h6>

                <div className="row">
                {content?.files?.length == 0 && <div className=" col-md-12 alert alert-info">No files added!</div>}
                    {content?.files?.map(item => <div className="col-md-6" key={item.id}>
                        <div className="card mb-2">
                            <div className="card-body">
                            {displayFile(item)} <br></br> <button title={`Delete: ${item?.file?.name} ; file`}
                            className="btn btn-xs btn-outline-danger" type="button"
                            onClick={() => deleteContentFile(content.id, item)}>Delete</button>
                            </div>
                        </div>
                    </div>)}
                </div>
                <br />
                <h6>URLS</h6>
                <ul className="list-group">
                    {content?.urls?.length == 0 && <li className="list-group-item">No external links added!</li>}
                    {content?.urls?.map(item => <li className="list-group-item" key={item.id}>
                        <b>#{item.id}</b>. <a href={item.url} target="_blank">{item.url}</a> &nbsp;
                        <button className="btn btn-xs btn-outline-danger" type="button" onClick={() => deleteContentUrl(content.id, item)}>Delete</button>
                    </li>)}
                </ul>
            </div>
        </div>
    </div>
}

export default ContentCard