import React, { useEffect, useState } from 'react';
import AdminSideBar from './AdminSideBar';
import axiosInstance from '../config/axiosInstance';
import courseIntro from '../assets/images/service-definition-flow.png';

function AdminIndex() {

    const [alertType, setAlertType] = useState('info')
    const [alertMessage , setAlertMessage] = useState('')
    const [ isFetchingUserInformation, setIsFetchingUserInformation] = useState(false)
    const [ authenticatedUser, setAuthenticatedUser] = useState({username: '', email: '', first_name: '', last_name: ''})
    
    useEffect(() => {
        fetchUserInformation()
    }, [])

    

    const fetchUserInformation = async () => {
        setIsFetchingUserInformation(true)
        setAlertMessage("Fetching data.....")
        try {
            const response = await axiosInstance.get('/user-info/')
            const responseData = response.data
            const {message,data} = responseData
            setAuthenticatedUser(data)
            setAlertMessage(message)
            setAlertType("success")
            
        } catch (error) {
            setAlertMessage("Error occured , try login again.")
            setAlertType("danger")
        } finally {
            setTimeout(()=>{
                setIsFetchingUserInformation(false)
            },1000)
        }
    }

    return (
        <div className="container-fluid">
            
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-4 mt-1">
                    <div className="card mt-2  mr-2" >
                        <div className={`alert alert-${alertType} m-3 ${!isFetchingUserInformation ? "d-none" : ""}`}>
                            {alertMessage}
                        </div>
                        <div className="card-body">
                            <h5 className="card-title mb-3">Welcome {authenticatedUser.username}</h5>
                            <table className='table table-bordered'>
                                <tbody>
                                    <tr>
                                        <td>Firstname</td>
                                        <td>{authenticatedUser.first_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Lastname</td>
                                        <td>{authenticatedUser.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{authenticatedUser.email}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-md-5 mt-1'>
                    <div className='card mt-2 ml-2 '>
                        <div className='card-body'>
                            <h5 className="card-title mb-3">Service definition flow</h5>
                            <img src={courseIntro} alt="service definition flow" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AdminIndex;