import React, { useEffect, useRef, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import ServicesTable from '../partials/ServicesTable';
import axiosInstance from '../../config/axiosInstance';
import ServiceForm from '../partials/ServiceForm';
import { getImagePath } from './CreateServiceOffering';
import { Link } from 'react-router-dom';


function ServicesIndex() {

    useEffect(() => {
        fetchServicesData()
    }, [])

    const fetchServicesData = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.get('/services/')

            if (response.status == 200) {
                const responseData = response.data
                setServices(responseData.data)
            }
        } catch (error) {
        } finally {
        }

        setIsLoading(false)

    }

    const [isLoading, setIsLoading] = useState(false) //
    const [alertType, setAlertType] = useState("info")
    const [services, setServices] = useState([])
    const [serviceFormData, setServiceFormData] = useState({
        "title": "",
        "id": "",
        "description": "",
        "name_id":"",
        "topics": []
    })
    const fileInputRef = useRef(null);
    const [formAlertMessage, setFormAlertMessage] = useState("")
    const [isEditting, setIsEditting] = useState(false)
    const [isFormSendingData, setIsFormSendingData] = useState(false)
    const [serviceId, setServiceId] = useState(null)

    const handleFormDataSubmission = async (event) => {
        event.preventDefault();
        setIsFormSendingData(true)
        setFormAlertMessage("Submitting data , please wait")

        const file = fileInputRef.current.files[0];
        const formData = new FormData();
        if (file) {
            formData.append("image", file);
        } else {
        }

        formData.append("title", serviceFormData.title);
        formData.append("name_id", slugify(serviceFormData.title));
        formData.append("description", serviceFormData.description);

        if (isEditting) {
            editServiceData(formData)
        } 

    }

    function slugify(str) {
        return str
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with '-'
            .replace(/^-+|-+$/g, '');    // Remove leading/trailing '-'
    }
    


    const editServiceData = async (formData) => {
        if(!serviceId){
            setFormAlertMessage("Error ocurred , try reload your page")
            setAlertType("danger")
            return
        }
        try {
            const response = await axiosInstance.put(`/services/${serviceId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            const responseData = response.data
            const { message, data } = responseData
            setFormAlertMessage(message)
            setAlertType("success")
            const service = { ...data, "image": getImagePath(data.image) }
            setServices(prev => {
                return prev.map(item => {
                    if (item.id == service.id) return service
                    return item
                })
            })
            setTimeout(()=>{
                resetServiceForm()
                setIsEditting(false)
            },1000)
        } catch (error) {
            const { message } = error
            setFormAlertMessage(message)
            setAlertType("danger")
        } finally {
            setTimeout(() => {
                setAlertType('info')
                setIsFormSendingData(false)
            }, 2000)
        }
    }


    const handleServiceFormDataChange = (event) => {
        
        let { name, value } = event.target;
        
        setServiceFormData({
            ...serviceFormData,
            "name_id":slugify(serviceFormData.title),
            [name]: value,
        });
    };


    const resetServiceForm = () => {
        setIsFormSendingData(false)
        setFormAlertMessage("")
        setServiceId(null)
        setAlertType('info')
        setServiceFormData({ "title": "", "description": "" })
        fileInputRef.current.value = "";
    }


    const editService = (service) => {
        resetServiceForm()
        setServiceFormData({ ...service,"name_id": slugify(service.title)})
        setServiceId(service.id)
        setIsEditting(true)
    }



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <div className={`row ${isEditting ? "d-none" : ""}`}>
                        <h4 className="pt-3 text-center">Manage services & their offerings</h4>
                        <small>Click the view button to update their offerings</small>
                        <div className={`loader  ${isLoading ? '' : 'd-none'}`}></div>
                        <Link to={"/admin/create-service"} className="btn btn-outline-primary" 
                        style={{width:"250px",position:"absolute",right:"50px", marginTop:"10px"}} >Create new service</Link>
                        <ServicesTable editService={editService} services={services} />
                    </div>

                    <div className={`row ${!isEditting ? "d-none" : ""}`}>
                        <h4 className="pt-3">Edit Service</h4>
                        <div className={`loader  ${isLoading ? '' : 'd-none'}`}></div>
                        <div className='col-md-6 col-sm-12'>
                        <ServiceForm
                                    alertType={alertType}
                                    serviceFormData={serviceFormData}
                                    fileInputRef={fileInputRef}
                                    formAlertMessage={formAlertMessage}
                                    isEditting={isEditting}
                                    setIsEditting={setIsEditting}
                                    isFormSendingData={isFormSendingData}
                                    handleFormDataSubmission={handleFormDataSubmission}
                                    handleServiceFormDataChange={handleServiceFormDataChange} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ServicesIndex;