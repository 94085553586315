import React,{useState} from 'react';
import PaginationControls from '../partials/PaginationControls';
import FormatDate from '../partials/FormatDate';
function OrderItemsTable({orderItems}){
    // Pagination state

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the indices for the data to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orderItems.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(orderItems.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

    return <>
    <table className='table mt-2 table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order Number</th>
                      <th>Order Status</th>
                      <th>Customer</th>
                      <th>Created by</th>
                      <th>Plan Name</th>
                      <th>Plan Description</th>
                      <th>Plan Price</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map(orderItem => <tr key={orderItem.id}>
                      <td>{orderItem.id}</td>
                      <td>{orderItem?.order?.order_number}</td>
                      <td>{orderItem?.order?.status}</td>
                      <td>{orderItem?.order?.user.email}</td>
                      <td>{orderItem?.order?.created_by?.email}</td>
                      <td>{orderItem?.service_plan?.name}</td>
                      <td>{orderItem?.service_plan?.description}</td>
                      <td>${orderItem?.service_plan?.price}</td>
                      <td><FormatDate dateString={orderItem.created_at} /></td>
                      <td><FormatDate dateString={orderItem.updated_at} /></td>
                    </tr>)}
                    {currentItems.length==0 && <tr key={1}><td className='text-center' colSpan={10}>No data available at the moment</td></tr>}
                  </tbody>
                </table>
                {/* Pagination Controls */}
                <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
    </>
}


export default OrderItemsTable