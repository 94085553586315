import React, { useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar";
import CustomAlertMessage from "../partials/CustomAlertMessage";
import axiosInstance from "../../config/axiosInstance";
import { Link } from "react-router-dom";
import FormatDate from "../partials/FormatDate";

const QuestionsGroupIndex = () => {
    const [alertMessage, setAlertMessage] = useState({
        "type": "info",
        "display": false,
        "message": "",
        "isLoading": false
    })


    const [questionsGroups, setQuestionsGroups] = useState([])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {

        setAlertMessage({ "type": "info", display: true, isLoading: true, message: "Fetching data.... please wait!" })
        try {
            const { data: { data } } = await axiosInstance.get(`/admin/resume-group-questions/`)

            const grouped = groupByQuestionGroup(data)

            setQuestionsGroups(grouped)
        } catch (error) {
            setAlertMessage(prev => ({ ...prev, type: "danger", message: "Error ocurred , contact administrator." }))
        } finally {
            setTimeout(() => {
                setAlertMessage({
                    "type": "info",
                    "display": false,
                    "message": "",
                    "isLoading": false
                })

            }, 1000)
        }
    }



    function groupByQuestionGroup(data) {
        const groupedData = data.reduce((acc, item) => {
            const { question_group,id, question_group_name,created_at,updated_at, question, question_text } = item;

            let group = acc.find(g => g.question_group_id === question_group);

            if (!group) {
                group = {
                    question_group_id: question_group,
                    id: id,
                    created_at: created_at,
                    updated_at: updated_at,
                    question_group_name: question_group_name,
                    questions: []
                };
                acc.push(group);
            }

            group.questions.push({
                question_id: question,
                question_text: question_text
            });

            return acc;
        }, []);

        return groupedData;
    }



    return (
        <div className="container-fluid">

            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10 mt-1">
                    <div className="card mt-2  mr-2" >

                        <div className="card-body">
                            <h5 className="card-title mb-3">Questions with groups matched</h5>
                            <Link to={"/admin/match-resume-questions-to-group/create"} className="btn btn-outline-primary" style={{ position: "absolute", top: "10px", right: "50px" }} >Match question to group</Link>
                            <CustomAlertMessage alertType={alertMessage.type}
                                showAlertMessage={alertMessage.display}
                                alertMessage={alertMessage.message}
                            />

                            <div className="col-md-12">

                                <table className="table mt-2 table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Group name</th>
                                            <th>Questions</th>
                                            <th>Created At</th>
                                            <th>Updated At</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {questionsGroups.map(item => <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.question_group_name}</td>
                                            <td>{item.questions.map((q,index)=><li className="list-group-item" key={index}>{q.question_text}</li>)}</td>
                                            <td><FormatDate dateString={item.created_at}/></td>
                                            <td><FormatDate dateString={item.updated_at}/></td>
                                            <td>Coming soon!</td>
                                        </tr>)}

                                        {questionsGroups.length == 0 && <tr><td colSpan={6} className="text-center">No data found!</td></tr>}

                                    </tbody>

                                </table>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default QuestionsGroupIndex;
